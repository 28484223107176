import React from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import './index.scss';

const Modal = ({
	isVisible,
	hide,
	modifierPopupClass = '',
	children = null,
	container = document.body
}) => {
	let popupClass = cn('', {
		popup_visible: isVisible
	});

	return (
		<>
			{createPortal(
				<div className={`popup ${modifierPopupClass} ${popupClass}`}>
					<div className='popup__bg' onClick={hide}></div>
					<div className='popup__wrap'>
						<button className='popup__close' onClick={hide}></button>
						{children}
					</div>
				</div>,
				container
			)}
		</>
	);
};

export default Modal;
