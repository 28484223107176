import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';

import { UPLOAD_IMG_URL } from '../../constants';

import './index.scss';

const Baloon = (
	{
		className,
		handleTglPopup,
		id,
		name,
		img,
		coords,
		currentBaloon,
		handleEnter,
		handleLeave,
		zoom
	},
	ref
) => {
	const handleChoose = id => {
		handleTglPopup(id);
	};

	return (
		<button
			className={cn(`${className} baloon`, {
				baloon_hide: currentBaloon !== id && currentBaloon > 0,
				baloon_current: currentBaloon === id
			})}
			id={id}
			style={{
				gridArea: coords
				// transform: `scale(${3 / zoom})`
			}}
			onClick={() => handleChoose(id)}
			onMouseEnter={() => handleEnter(id)}
			onMouseLeave={handleLeave}
		>
			<div className='baloon__icon'>
				<img src={`${UPLOAD_IMG_URL}${img}`} alt={name} />
			</div>
			{/* <span className='baloon__name'>{name}</span> */}
		</button>
	);
};

export default Baloon;
