/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef } from 'react';
import Swiper from 'react-id-swiper';
import SwiperCore, { EffectFade } from 'swiper';

import { UPLOAD_IMG_URL, IMG_URL } from '../../constants';

import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import './index.scss';

SwiperCore.use([EffectFade]);

const PinSlider = ({ className, slider, videos }) => {
	const ref = useRef(null);

	const params = {
		loop: true,
		speed: 500,
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 24,
		preventClicks: false,
		preventClicksPropagation: false,
		effect: 'fade'
	};

	const goNext = () => {
		if (ref.current !== null && ref.current.swiper !== null) {
			ref.current.swiper.slideNext();
		}
	};

	const goPrev = () => {
		if (ref.current !== null && ref.current.swiper !== null) {
			ref.current.swiper.slidePrev();
		}
	};
	return (
		<div className={`${className} pin-slider-wrap`}>
			<Swiper
				ref={ref}
				{...params}
				containerClass='pin-slider-wrap__slider pin-slider'
			>
				{videos && videos.map((video, i) => {
					return (
						<div key={video.src + i} className='pin-slider__video'>
							<video  controls src={video.src}></video>
						</div>
					);
				})}
				{slider.map((el, i) => {
					return (
						<div className='pin-slider__item' key={i}>
							<img
								src={`${UPLOAD_IMG_URL}${el}`}
							/>
						</div>
					);
				})}
			</Swiper>
			<button
				className='pin-slider-wrap__sl-prev sl-btn sl-btn_prev sl-btn_popup'
				onClick={goPrev}
			></button>
			<button
				className='pin-slider-wrap__sl-next sl-btn sl-btn_next sl-btn_popup'
				onClick={goNext}
			></button>
		</div>
	);
};

export default PinSlider;
