import React from 'react';
import css from './index.module.scss';
import cn from 'classnames';

import { selectDeviceSize } from '../../reducers/deviceSlice';
import { useSelector } from 'react-redux';

import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useState } from 'react';

import Slide from './Slide';
import { useCallback } from 'react';

const WherebuySlider = ({ data, className, current }) => {
	const sliderRef = React.useRef();
	const windowWidth = useSelector(selectDeviceSize);
	const [slidesCount, setSlidesCount] = useState();
	const [slides, setSlides] = useState([]);
	const [prevDisabled, setPrevDisabled] = useState(true);
	const [nextDisabled, setNextDisabled] = useState(false);

	const checkDisabledButtons = useCallback(
		swiper => {
			if (swiper.activeIndex === 0) {
				setPrevDisabled(true);
			} else {
				setPrevDisabled(false);
			}

			if (swiper.activeIndex === slides.length - 1) {
				setNextDisabled(true);
			} else {
				setNextDisabled(false);
			}
		},
		[slides]
	);

	useEffect(() => {
		const rowCount = 3;

		setSlidesCount(
			(windowWidth >= 1024 ? 5 : windowWidth >= 768 ? 4 : 2) * rowCount
		);
	}, [windowWidth]);

	useEffect(() => {
		const newSlides = data.reduce(
			(slides, slide) => {
				if (slides[slides.length - 1].length === slidesCount) {
					slides.push([]);
				}

				slides[slides.length - 1].push(slide);
				return slides;
			},
			[[]]
		);

		setSlides(newSlides);
	}, [data, slidesCount]);

	useEffect(() => {
		const { current: slider } = sliderRef;

		if (!slider || !slider.swiper) return;

		checkDisabledButtons(slider.swiper);
	}, [checkDisabledButtons]);

	const handleNext = () => {
		const { current: slider } = sliderRef;

		if (!slider || !slider.swiper) return;
		slider.swiper.slideNext();
	};

	const handlePrev = () => {
		const { current: slider } = sliderRef;

		if (!slider || !slider.swiper) return;
		slider.swiper.slidePrev();
	};

	if (!data.length) {
		return <div>Пусто</div>;
	}

	return (
		<div className={cn(css.slider, className)}>
			<Swiper
				ref={sliderRef}
				className={css.slider__list}
				onSlideChange={checkDisabledButtons}
				slidesPerView={1}
			>
				{slides.map(slide => (
					<SwiperSlide
						key={`slide-${slide[0].id}`}
						className={css.slider__item}
					>
						<Slide data={slide} />
					</SwiperSlide>
				))}
			</Swiper>

			{slides.length > 1 && (
				<>
					<button
						className={cn(
							css.slider__button,
							css.slider__button_prev,
							'sl-btn sl-btn_prev'
						)}
						onClick={handlePrev}
						disabled={prevDisabled}
					></button>

					<button
						className={cn(
							css.slider__button,
							css.slider__button_next,
							'sl-btn sl-btn_next'
						)}
						onClick={handleNext}
						disabled={nextDisabled}
					></button>
				</>
			)}
		</div>
	);
};

export default WherebuySlider;
