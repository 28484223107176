import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { selectContacts, loadContacts } from '../../reducers/contactsSlice';
import { selectLang } from '../../reducers/deviceSlice';
import ContactsBlock from '../../components/ContactsBlock';
import ContactsForm from '../../components/ContactsForm';

import './index.scss';
import Layout from '../../components/Layout';

const Contacts = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const contactsData = useSelector(selectContacts);

	const { isLoaded, title, contacts } = contactsData;
	const lang = useSelector(selectLang);

	useEffect(() => {
		if (lang) {
			dispatch(loadContacts('contacts', lang));
		}
	}, [lang]);

	return (
		<Layout>
			<Helmet>
				<title>{t('page_title.contacts')}</title>
			</Helmet>
			{isLoaded && (
				<section className='sect-contacts sect-contacts_page'>
					<div className='sect-contacts__container container'>
						<div className='row'>
							<div className='col-12 col-sm-10 col-md-5 col-lg-5'>
								<h1
									className='sect-contacts__title h2'
									dangerouslySetInnerHTML={{
										__html: title
									}}
								></h1>
								<div className='contacts-col'>
									{contacts.map((item, i) => {
										return (
											<div className='contacts-col__group' key={i}>
												<ContactsBlock {...item} />
											</div>
										);
									})}
								</div>
							</div>
						</div>
						<ContactsForm className='sect-contacts__form sect-contacts__form_sticky' />
					</div>
				</section>
			)}
		</Layout>
	);
};

export default Contacts;
