import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UPLOAD_IMG_URL, DOMAIN_URL } from '../../constants';

import './index.scss';

const StockCard = ({ img, name, desc, id }) => {
	const { t } = useTranslation();
	const { lang } = useParams();

	return (
		<div className='stock-card'>
			<div className='stock-card__prev'>
				<img src={`${UPLOAD_IMG_URL}${img}`} alt={name} />
			</div>
			<h5
				className='stock-card__name'
				dangerouslySetInnerHTML={{
					__html: name
				}}
			></h5>
			<p
				className='stock-card__desc desc'
				dangerouslySetInnerHTML={{
					__html: desc
				}}
			></p>
			<Link
				to={`${DOMAIN_URL}${lang}/stock/${id}`}
				className='stock-card__link link'
			>
				{t('more')}
			</Link>
		</div>
	);
};

export default StockCard;
