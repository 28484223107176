import React, { useState } from 'react';
import cn from 'classnames';
import AnimateHeight from 'react-animate-height';

import './index.scss';

import { ReactComponent as PlusIcon } from '../../assets/img/icons/plus.svg';

const InfoItem = ({ title, desc }) => {
	const [show, setShow] = useState(false);

	return (
		<li className='info-list__item info-item'>
			<button
				className={cn('info-item__btn', {
					'info-item__btn_show': show
				})}
				onClick={() => setShow(!show)}
			>
				<PlusIcon />
				<span>{title}</span>
			</button>
			<AnimateHeight height={show ? 'auto' : 0} duration={200}>
				<div
					className='info-item__hidden b-content'
					dangerouslySetInnerHTML={{
						__html: desc
					}}
				></div>
			</AnimateHeight>
		</li>
	);
};

export default InfoItem;
