import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Draggable from 'react-draggable';
import cn from 'classnames';

import CityItem from '../../components/CityItem';
import Baloon from '../../components/Baloon';
import BaloonName from '../../components/BaloonName';
import Modal from '../../components/Modal';
import PinPopup from '../../components/PinPopup';

import { IMG_URL, DOMAIN_URL } from '../../constants';
import { selectMap, loadMap } from '../../reducers/mapSlice';
import { selectPin, loadPin } from '../../reducers/pinSlice';
import {
	selectDeviceSize,
	selectDeviceHeight
} from '../../reducers/deviceSlice';

import './index.scss';

const scaleSize = [1, 1.25, 1.5, 2, 3, 4];

const Map = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const lang = localStorage.getItem('i18nextLng') || 'ru';
	const nodeRef = useRef(null);
	const nodeRefIcon = useRef(null);
	const ref = useRef(null);

	useEffect(() => {
		if (lang) {
			dispatch(loadMap('map', lang));
		}
	}, [lang]);

	const data = useSelector(selectMap);
	const wWidth = useSelector(selectDeviceSize);
	const wHeight = useSelector(selectDeviceHeight);

	const { isLoaded, city } = data;

	const [zoom, setZoom] = useState(0);
	const [isShow, setIsShow] = useState(false);
	const [pos, setPos] = useState({ x: 0, y: 0 });
	const [oldPos, setOldPos] = useState({ x: 0, y: 0 });
	// const [mapHeight, setMapHeight] = useState(0);
	const [bounds, setBounds] = useState({
		top: 0,
		bottom: 0,
		right: 0,
		left: 0
	});
	const [initialSize, setInitialSize] = useState({ width: 0, height: 0 });

	const [offsetHeight, setOffsetHeight] = useState(108);
	const [offsetWidht] = useState(40);

	useEffect(() => {
		if (isLoaded) {
			let offsetH;
			if (wWidth < 767) {
				offsetH = 156;
			} else {
				offsetH = 108;
			}
			setOffsetHeight(offsetH);
			let newTop;
			let newBot;
			const mapW = nodeRefIcon.current.getBoundingClientRect().width;
			const mapH = nodeRefIcon.current.getBoundingClientRect().height;
			const rightLeft = Math.round(Math.abs(wWidth - offsetWidht - mapW)) / 2;
			setInitialSize({
				width: mapW / scaleSize[zoom],
				height: mapH / scaleSize[zoom]
			});
			if (mapH > wHeight - offsetH) {
				let difH = Math.round(Math.abs(wHeight - mapH)) / 2;
				newTop = difH + offsetH + 60;
				newBot = difH - 40;
			} else {
				newTop = 0;
				newBot = 0;
			}
			setBounds({
				top: -newTop,
				bottom: newBot,
				right: rightLeft,
				left: -rightLeft
			});
			setOldPos({
				x: 0,
				y: 0
			});
			setPos({
				x: 0,
				y: 0
			});
		}
	}, [isLoaded, wWidth, wHeight]);

	const ZoomMap = newZoom => {
		const currentStep = zoom + newZoom;
		if (currentStep > scaleSize.length - 1 || currentStep < 0) return;
		setZoom(currentStep);
		let newTop;
		let newBot;
		const rightLeft = Math.round(
			Math.abs(
				wWidth -
					offsetWidht * scaleSize[currentStep] -
					initialSize.width * scaleSize[currentStep]
			) / 2
		);
		const currentHeight = initialSize.height * scaleSize[currentStep];
		if (currentHeight > wHeight - offsetHeight) {
			let difH = Math.round(Math.abs(wHeight - currentHeight) / 2);
			if (wWidth < wHeight && wWidth > 768) {
				newTop = difH + offsetHeight + 60;
				newBot = difH + 40;
			} else {
				newTop = difH + offsetHeight + 60;
				newBot = difH + 40 * scaleSize[currentStep];
			}
			setBounds({
				top: -newTop,
				bottom: newBot,
				right: rightLeft,
				left: -rightLeft
			});
		} else {
			newTop = (currentHeight - initialSize.height) / 2;
			setBounds({
				top: newTop,
				bottom: offsetHeight / 2,
				right: rightLeft,
				left: -rightLeft
			});
		}
		setPos({
			x: oldPos.x * scaleSize[currentStep],
			y: oldPos.y * scaleSize[currentStep]
		});
	};

	const handleZoom = step => {
		ZoomMap(step);
	};

	const handleWheel = e => {
		const delta = e.deltaY || e.detail || e.wheelDelta;
		ZoomMap(delta > 0 ? -1 : 1);
	};

	const dist = (p1, p2) => {
		return Math.round(
			Math.sqrt(
				Math.pow(p1.clientX - p2.clientX, 2) +
					Math.pow(p1.clientY - p2.clientY, 2)
			)
		);
	};

	const [distance, setDistance] = useState(0);
	const [currentBaloon, setCurrentBaloon] = useState(-1);

	const onControlledStart = e => {
		if (e.targetTouches && e.targetTouches.length === 2) {
			setCurrentBaloon(-1);
			setDistance(dist(e.targetTouches[0], e.targetTouches[1]));
		}
	};

	const onControlledStop = (e, position) => {
		const { x, y } = position;
		setPos({ x, y });
		setOldPos({ x: x / scaleSize[zoom], y: y / scaleSize[zoom] });
	};

	const props = {
		nodeRef,
		defaultPosition: { x: 0, y: 0 },
		position: pos,
		defaultClassNameDragging: 'map-wraper__wrap_no-dragged',
		onStop: onControlledStop,
		onStart: onControlledStart,
		bounds
	};

	let baloon;
	let baloonTop;
	let baloonLeft;
	let newX;
	let newY;
	let halfHeight;
	let halfWidth;

	const handlePickPoint = (e, id) => {
		e.preventDefault();
		e.stopPropagation();
		baloon = document.getElementById(id);
		setCurrentBaloon(id);
		baloonTop = baloon.offsetTop;
		baloonLeft = baloon.offsetLeft;
		halfHeight = initialSize.height / 2;
		halfWidth = initialSize.width / 2;
		newY = halfHeight - 40 - baloonTop - 9;
		newX = halfWidth - baloonLeft - 9;
		if (newY < 0) {
			if (Math.abs(newY) > Math.abs(bounds.top / scaleSize[zoom])) {
				newY = bounds.top / scaleSize[zoom];
			}
		} else {
			if (Math.abs(newY) > Math.abs(bounds.top / scaleSize[zoom])) {
				newY = bounds.bottom / scaleSize[zoom];
			}
		}
		if (newX < 0) {
			if (Math.abs(newX) > Math.abs(bounds.right / scaleSize[zoom])) {
				newX = bounds.left / scaleSize[zoom];
			}
		} else {
			if (Math.abs(newX) > Math.abs(bounds.right / scaleSize[zoom])) {
				newX = bounds.right / scaleSize[zoom];
			}
		}
		setIsShow(!isShow);
		setOldPos({ x: newX, y: newY });
		setPos({ x: newX * scaleSize[zoom], y: newY * scaleSize[zoom] });
	};

	useEffect(() => {
		function handleClickOutside(e) {
			if (ref.current && !ref.current.contains(e.target)) {
				setIsShow(false);
			}
		}
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		function handleClickMap() {
			if (currentBaloon !== -1) {
				setCurrentBaloon(-1);
			}
		}
		document.addEventListener('click', handleClickMap);
		return () => {
			document.removeEventListener('click', handleClickMap);
		};
	}, [currentBaloon]);

	const [isShowing, setIsShowing] = useState(false);
	const [isVisible, setIsVisible] = useState(false);

	const tglPopup = () => {
		if (isShowing) {
			setIsVisible(false);
			setTimeout(() => {
				setIsShowing(false);
			}, 300);
		} else {
			setIsShowing(true);
			setTimeout(() => {
				setIsVisible(true);
			}, 0);
		}
	};

	const handleTglPopup = id => {
		dispatch(loadPin(`map&mid=${id}`, lang));
		tglPopup();
	};

	const handleTouchStart = e => {
		const { id } = e.target;
		if (e.targetTouches && e.targetTouches.length === 1 && id) {
			dispatch(loadPin(`map&mid=${id}`, lang));
			tglPopup();
		}
	};

	const handleTouchMove = e => {
		setCurrentBaloon(-1);
		if (e.targetTouches && e.targetTouches.length === 2) {
			const newDist = dist(e.targetTouches[0], e.targetTouches[1]);
			const res = distance - newDist;
			if (res > 60) {
				ZoomMap(-1);
				setDistance(newDist);
			} else if (res < -59) {
				ZoomMap(1);
				setDistance(newDist);
			}
		}
	};

	const baloonProps = useSelector(selectPin);
	const { isLoadedPin, pin } = baloonProps;

	const handleEnter = id => {
		setCurrentBaloon(id);
	};

	const handleLeave = () => {
		setCurrentBaloon(-1);
	};

	return (
		<>
			<Helmet>
				<title>{t('page_title.map')}</title>
			</Helmet>
			<header className='header-map'>
				<div className='header-map__wrap header-map-wrap'>
					<Link
						to={`${DOMAIN_URL}${lang}`}
						className='header-map-wrap__link header-map-wrap__link_logo'
					>
						<img
							src={`${IMG_URL}/icons/logo-ru-sugar.svg`}
							alt='Русский сахар'
						/>
					</Link>
					<span className='header-map-wrap__title'>{t('page_title.map')}</span>
					{wWidth > 767 && (
						<Link to={`${DOMAIN_URL}${lang}`} className='header-map-wrap__link'>
							{t('return_back')}
						</Link>
					)}
				</div>
			</header>
			<main className='map-main'>
				<section className='sect-map'>
					<div className='sect-map__map map'>
						{wWidth > 767 && (
							<div className='map__zoom map-zoom'>
								<button
									className={cn('map-zoom__btn', {
										'map-zoom__btn_disabled': zoom === scaleSize.length - 1
									})}
									onClick={() => handleZoom(1)}
								></button>
								<button
									className={cn('map-zoom__btn', {
										'map-zoom__btn_disabled': zoom === 0
									})}
									onClick={() => handleZoom(-1)}
								></button>
							</div>
						)}
						<button
							className={cn('map__tgl-cities', {
								'map__tgl-cities_open': isShow
							})}
							onClick={() => setIsShow(!isShow)}
							ref={ref}
						>
							{wWidth < 768 && <span>Список городов</span>}
							<img src={`${IMG_URL}/icons/arrow-right.svg`} alt='open' />
						</button>
						{isLoaded && (
							<div
								className={cn('map__cities cities', {
									map__cities_show: isShow
								})}
							>
								<p className='cities__info h4'>
									Выберите город, чтобы увидеть его на карте
								</p>
								<ul className='cities__list city-list'>
									{city.map(el => {
										return (
											<CityItem
												handlePickPoint={handlePickPoint}
												className='city-list__item'
												key={el.id}
												{...el}
											/>
										);
									})}
								</ul>
							</div>
						)}
						<div className='map__wraper map-wraper'>
							<Draggable {...props}>
								<div
									className='map-wraper__wrap'
									ref={nodeRef}
									onWheel={handleWheel}
									onTouchStart={handleTouchStart}
									onTouchMove={handleTouchMove}
								>
									<img
										src={`${IMG_URL}/map5.png`}
										alt=''
										className='map-wraper__img'
										ref={nodeRefIcon}
										style={{
											transform: `scale(${scaleSize[zoom]})`
										}}
									/>
									<div
										className='map-wraper__grid'
										style={{
											transform: `scale(${scaleSize[zoom]})`
										}}
									>
										{isLoaded &&
											city.map(el => {
												return (
													<React.Fragment key={el.id}>
														<Baloon
															className='map-wraper__baloon'
															currentBaloon={currentBaloon}
															handleTglPopup={handleTglPopup}
															handleEnter={handleEnter}
															handleLeave={handleLeave}
															{...el}
															zoom={scaleSize[zoom]}
														/>
														<BaloonName
															className='map-wraper__baloon-name'
															name={el.name}
															coords={el.coords}
															zoom={scaleSize[zoom]}
															currentBaloon={currentBaloon === -1}
															current={currentBaloon === el.id}
														/>
													</React.Fragment>
												);
											})}
									</div>
								</div>
							</Draggable>
						</div>
					</div>
				</section>
			</main>

			{isLoadedPin && isShowing && (
				<Modal
					isVisible={isVisible}
					modifierPopupClass='popup_map'
					hide={tglPopup}
				>
					<PinPopup {...pin} hide={tglPopup} />
				</Modal>
			)}
		</>
	);
};

export default Map;
