import { createSlice } from '@reduxjs/toolkit';

import { loadData as loadDatatApi } from '../api';

const aboutInitialState = {
	title: '',
	desc: '',
	quote: '',
	content: '',
	img: null,
	srcSet: null,
	percentage: [],
	photo: {},
	isLoading: false,
	isLoaded: false,
	error: null
};

export const slice = createSlice({
	name: 'about',
	initialState: aboutInitialState,
	reducers: {
		getDataStart: state => {
			state.isLoading = true;
		},
		getDataSuccess: (state, { payload }) => {
			state.title = payload.title;
			state.desc = payload.desc;
			state.quote = payload.quote;
			state.content = payload.content;
			state.img = payload.img;
			state.srcSet = payload.srcSet;
			state.percentage = payload.percentage;
			state.photo = payload.photo;
			state.isLoading = false;
			state.isLoaded = true;
		},
		getDataFailure: (state, { payload }) => {
			state.isLoading = false;
			state.error = payload;
		}
	}
});

const { getDataStart, getDataSuccess, getDataFailure } = slice.actions;

export const loadAbout = (path, lang) => async dispatch => {
	dispatch(getDataStart());
	try {
		const data = await loadDatatApi(path, lang);
		dispatch(getDataSuccess(data));
	} catch (err) {
		dispatch(getDataFailure({ error: err.toString() }));
	}
};

export const selectAbout = state => state.about;

export default slice.reducer;
