import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, Link, useLocation, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { selectDeviceSize, setLang } from '../../reducers/deviceSlice';
import { IMG_URL, DOMAIN_URL } from '../../constants';
import { selectStocks } from '../../reducers/stocksSlice';

import { wherebuySelector } from '../../reducers/wherebuySlice';

import { handleAnchor } from '../../helpers';

import './index.scss';

const Header = () => {
	const dispatch = useDispatch();
	const root = document.querySelector('.root');
	const ref = useRef(null);
	const [sticky, setSticky] = useState(false);
	const [prevSt, setPrevSt] = useState(0);
	const [st, setSt] = useState(0);
	const [show, setShow] = useState(false);
	const [menuDisplay, setMenuDisplay] = useState(false);
	const [menuVisible, setMenuVisible] = useState(false);
	const wWidth = useSelector(selectDeviceSize);

	const { t } = useTranslation();
	const lang = localStorage.getItem('i18nextLng') || 'ru';

	const { data: wherebuyData } = useSelector(wherebuySelector);

	const location = useLocation();
	const history = useHistory();

	const stocksData = useSelector(selectStocks);
	const length =
		stocksData.stocks !== undefined && stocksData.stocks.length > 1;

	const nav = [
		{
			path: 'about',
			name: t('menu.about'),
			id: 0
		},
		{
			path: 'catalog',
			name: t('menu.catalog'),
			id: 1
		},
		{
			path: 'stocks',
			name: t('menu.stocks'),
			id: 2
		},
		{
			path: 'info',
			name: t('menu.info'),
			id: 3
		},
		{
			path: 'imap',
			name: t('menu.map'),
			id: 4
		},
		{
			path: 'leopard',
			name: t('menu.leo'),
			id: 5
		},
		{
			path: 'contacts',
			name: t('menu.contacts'),
			id: 6
		},
		{
			path: '#wherebuy',
			name: t('menu.wheretobuy'),
			id: 7
		}
	];

	const langStorage = localStorage.getItem('i18nextLng') || 'ru';
	useEffect(() => {
		dispatch(setLang(langStorage));
	}, [langStorage]);

	useEffect(() => {
		let timeout = null;
		if (wWidth < 768) {
			const handleScroll = () => {
				timeout = setTimeout(() => {
					setSt(root.scrollTop);
					setSticky(prevSt < st && root.scrollTop > 100);
					setPrevSt(st);
				}, 100);
			};

			root.addEventListener('scroll', handleScroll);

			return () => {
				root.removeEventListener('scroll', handleScroll);
				clearTimeout(timeout);
			};
		}
		if (wWidth > 1279) {
			root.classList.remove('root_fixed');
			setMenuVisible(false);
			setMenuDisplay(false);
		}
	}, [prevSt, st, wWidth, root]);

	useEffect(() => {
		function handleClickOutside(e) {
			if (ref.current && !ref.current.contains(e.target)) {
				setShow(false);
			}
		}
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	// const renderToWherebuy = text => {
	// 	return (
	// 		<a
	// 			className=''
	// 			href='#wherebuy'
	// 			onClick={event => {
	// 				if (location.pathname === `${DOMAIN_URL}${lang}`) {
	// 					handleAnchor(event);
	// 					return;
	// 				}
	// 				history.push(`${DOMAIN_URL}${lang}#wherebuy`);
	// 			}}
	// 		>
	// 			{text}
	// 		</a>
	// 	);
	// };

	const handleTglMenu = () => {
		if (wWidth < 1280) {
			if (menuDisplay) {
				setMenuVisible(false);
				setTimeout(() => {
					setMenuDisplay(false);
					root.classList.remove('root_fixed');
				}, 300);
			} else {
				setMenuDisplay(true);
				root.classList.add('root_fixed');
				setTimeout(() => {
					setMenuVisible(true);
				}, 0);
			}
		}
	};

	let classBurger = cn('btn-burger', {
		'btn-burger_open': !menuVisible,
		'btn-burger_close': menuVisible
	});

	let classHeaderMenu = cn('header-wrap__nav header-nav', {
		'header-nav_visible': menuVisible
	});

	return (
		<header
			className={cn('header', {
				header_transform: sticky && wWidth < 768
			})}
		>
			<div className='container'>
				<div className='row'>
					<div className='col-12'>
						<div className='header__wrap header-wrap'>
							<div className='header-wrap__logo-links'>
								<a
									href='https://russiansugar.com'
									className='header__link'
									target='_blank'
									rel='noreferrer'
								>
									<img
										src={`${IMG_URL}/icons/logo-gc-rusagro.svg`}
										alt='РУСАГРО'
									/>
								</a>
								<Link to={`${DOMAIN_URL}${lang}`} className='header__link'>
									<img
										src={`${IMG_URL}/icons/logo-ru-sugar.svg`}
										alt='Русский сахар'
									/>
								</Link>
							</div>
							{wWidth < 1280 ? (
								<button
									className={`${classBurger} header-wrap__burger`}
									onClick={handleTglMenu}
								>
									<div className='btn-burger__icon'>
										<span></span>
									</div>
								</button>
							) : (
								<nav className='header-wrap__nav header-nav'>
									<ul className='header-nav__list nav-list'>
										{nav.map(({ name, path, id }, index) => {
											if (path === 'stocks' && !length) return null;
											return (
												<li className='nav-list__item' key={id}>
													<NavLink
														to={`${DOMAIN_URL}${lang}/${path}`}
														activeClassName={window.location.href.includes(path) ? 'active' : ''}
													>
														{name}
													</NavLink>
												</li>
											);
										})}
{/* 
										{!!wherebuyData && (
											<li className='nav-list__item'>
												{renderToWherebuy(wherebuyData.title)}
											</li>
										)} */}
									</ul>
									<div className='header-nav__lang lang'>
										<button
											ref={ref}
											onClick={() => setShow(!show)}
											className={cn('lang__btn', {
												lang__btn_show: show
											})}
										>
											{!!lang && lang.toUpperCase()}
										</button>
										{show && (
											<div className='lang__dropdown lang-dropdown'>
												{lang !== 'ru' && (
													<Link
														to={`/ru${location.pathname.substr(3)}`}
														className='lang-dropdown__btn'
													>
														RU
													</Link>
												)}
												{lang !== 'en' && (
													<Link
														to={`/en${location.pathname.substr(3)}`}
														className='lang-dropdown__btn'
													>
														EN
													</Link>
												)}
												{lang !== 'ch' && (
													<Link
														to={`/ch${location.pathname.substr(3)}`}
														className='lang-dropdown__btn'
													>
														CH
													</Link>
												)}
											</div>
										)}
									</div>
								</nav>
							)}
							{wWidth < 1280 && menuDisplay
								? createPortal(
										<nav className={classHeaderMenu}>
											<ul className='header-nav__list nav-list'>
												{nav.map(({ name, path, id }) => {
													if (path === 'stocks' && !length) return null;
													return (
														<li className='nav-list__item' key={id}>
															<NavLink
																to={`${DOMAIN_URL}${lang}/${path}`}
																activeClassName={window.location.href.includes(path) ? 'active' : ''}
																onClick={handleTglMenu}
															>
																{name}
															</NavLink>
														</li>
													);
												})}

												{/* {!!wherebuyData && (
													<li className='nav-list__item'>
														{renderToWherebuy(wherebuyData.title)}
													</li>
												)} */}
											</ul>
											<div className='header-nav__lang lang'>
												<div className='lang__dropdown lang-dropdown'>
													<Link
														to={`/ru${location.pathname.substr(3)}`}
														className={cn('lang-dropdown__btn', {
															'lang-dropdown__btn_active': lang === 'ru'
														})}
													>
														RU
													</Link>
													<Link
														to={`/en${location.pathname.substr(3)}`}
														className={cn('lang-dropdown__btn', {
															'lang-dropdown__btn_active': lang === 'en'
														})}
													>
														EN
													</Link>
													<Link
														to={`/ch${location.pathname.substr(3)}`}
														className={cn('lang-dropdown__btn', {
															'lang-dropdown__btn_active': lang === 'ch'
														})}
													>
														CH
													</Link>
												</div>
											</div>
										</nav>,
										document.body
								  )
								: null}
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
