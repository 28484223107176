import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { selectStock, loadStock } from '../../reducers/stockSlice';
import { selectLang } from '../../reducers/deviceSlice';
import StockCard from '../../components/StockCard';
import Layout from '../../components/Layout';

import { UPLOAD_IMG_URL } from '../../constants';

import 'swiper/swiper.scss';
import './index.scss';

const Stock = () => {
	const { t } = useTranslation();
	const ref = useRef(null);

	const dispatch = useDispatch();
	const stock = useSelector(selectStock);
	let { id } = useParams();
	const { img, title, isLoaded, catalog, content } = stock;
	const lang = useSelector(selectLang);

	useEffect(() => {
		if (lang) {
			dispatch(loadStock(`sale&pid=${id}`, lang));
		}
	}, [lang]);

	const params = {
		loop: false,
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 24,
		preventClicks: false,
		preventClicksPropagation: false,
		breakpoints: {
			768: {
				slidesPerView: 2
			}
		}
	};

	const goNext = () => {
		if (ref.current !== null && ref.current.swiper !== null) {
			ref.current.swiper.slideNext();
		}
	};

	const goPrev = () => {
		if (ref.current !== null && ref.current.swiper !== null) {
			ref.current.swiper.slidePrev();
		}
	};

	return (
		<Layout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			{isLoaded && (
				<>
					<section className='stock'>
						<div className='container'>
							<div className='row'>
								<div className='col-12'>
									<div className='stock__info'>
										<div className='stock__prev'>
											<img src={`${UPLOAD_IMG_URL}${img}`} alt={title} />
										</div>
										<div
											className='stock__content b-content'
											dangerouslySetInnerHTML={{ __html: content }}
										></div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className='other-stock'>
						<div className='container'>
							<div className='row'>
								<div className='col-12'>
									<h2 className='other-stock__title'>
										{t('titles.other_stoks')}
									</h2>
									<div className='other-stock__wrap other-stock-wrap'>
										<Swiper
											ref={ref}
											{...params}
											containerClass='other-stock-slider'
										>
											{catalog.map(el => {
												return (
													<div
														className='other-stock-slider__slide stocks-card'
														key={el.id}
													>
														<StockCard {...el} />
													</div>
												);
											})}
										</Swiper>
										<button
											className='other-stock-wrap__sl-prev sl-btn sl-btn_prev'
											onClick={goPrev}
										></button>
										<button
											className='other-stock-wrap__sl-next sl-btn sl-btn_next'
											onClick={goNext}
										></button>
									</div>
								</div>
							</div>
						</div>
					</section>
				</>
			)}
		</Layout>
	);
};

export default Stock;
