export const handleAnchor = event => {
	event.preventDefault();
	const id = event.currentTarget.getAttribute('href').slice(1);
	smoothScrsoll(id);
};

export const smoothScrsoll = id => {
	const section = document.getElementById(id.replace('#', ''));

	if (section) {
		section.scrollIntoView({
			behavior: 'smooth'
		});
	}
};
