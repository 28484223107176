import { configureStore } from '@reduxjs/toolkit';

import dataReducer from './dataSlice';
import deviceReducer from './deviceSlice';
import stocksReducer from './stocksSlice';
import aboutReducer from './aboutSlice';
import catalogReducer from './catalogSlice';
import infoReducer from './infoSlice';
import contactsReducer from './contactsSlice';
import mapReducer from './mapSlice';
import pinReducer from './pinSlice';
import productReducer from './productSlice';
import stockReducer from './stockSlice';
import shortUrlReducer from './shortUrlSlice';
import wherebuyReducer from './wherebuySlice';
import leopardusSlice from './leopardusSlice';

export default configureStore({
	reducer: {
		data: dataReducer,
		device: deviceReducer,
		stocks: stocksReducer,
		about: aboutReducer,
		catalog: catalogReducer,
		info: infoReducer,
		contacts: contactsReducer,
		map: mapReducer,
		pin: pinReducer,
		product: productReducer,
		stock: stockReducer,
		shorturl: shortUrlReducer,
		wherebuy: wherebuyReducer,
		leopardus: leopardusSlice
	}
});
