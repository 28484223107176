import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { sendFormData as sendFormDataApi } from '../../api';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import { IMG_URL } from '../../constants';

import './index.scss';

const ContactsForm = ({ className }) => {
	const { t } = useTranslation();
	const schema = yup.object().shape({
		name: yup.string().required(t('form.name_error')),
		email: yup
			.string()
			.email(t('form.email_error'))
			.required(t('form.email_empty')),
		mess: yup.string().required(t('form.email_error'))
	});

	const { register, handleSubmit, reset, errors } = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema)
	});

	// const [sendForm, setSendForm] = useState(false);
	const [isShowing, setIsShowing] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [checked, setChecked] = useState(false);

	const tglPopup = () => {
		if (isShowing) {
			setIsVisible(false);
			setTimeout(() => {
				setIsShowing(false);
			}, 300);
		} else {
			setIsShowing(true);
			setTimeout(() => {
				setIsVisible(true);
			}, 0);
		}
	};

	const formReset = () => {
		reset();
		setChecked(false);
	};

	const submitForm = async data => {
		try {
			const body = await sendFormDataApi(data);
			if (`${body.success}` === '1') {
				tglPopup();
				formReset();
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			<form
				className={`${className} form`}
				onSubmit={handleSubmit(submitForm)}
				noValidate
			>
				<div className='form__main'>
					<h4 className='form__title'>{t('form.title')}</h4>
					<p className='form__desc desc'>{t('form.desc')}</p>
					<div className='form__formfields'>
						<div
							className={cn('form__formfield formfield', {
								formfield_error: errors.name
							})}
						>
							<input
								type='text'
								className='formfield__input'
								placeholder={t('form.name_placeholder')}
								name='name'
								id='name'
								ref={register}
							/>
							{!!errors.name && !!errors.name.message && (
								<span className='formfield__error'>{errors.name.message}</span>
							)}
						</div>
						<div
							className={cn('form__formfield formfield', {
								formfield_error: errors.email
							})}
						>
							<input
								type='email'
								className='formfield__input'
								placeholder={t('form.email_placeholder')}
								name='email'
								id='email'
								ref={register}
							/>
							{!!errors.email && !!errors.email.message && (
								<span className='formfield__error'>{errors.email.message}</span>
							)}
						</div>
					</div>
					<div
						className={cn('form__textarea formfield', {
							formfield_error: errors.message
						})}
					>
						<textarea
							name='mess'
							id='message'
							className='formfield__textarea'
							placeholder={t('form.mess_placeholder')}
							ref={register}
						></textarea>
						{!!errors.mess && !!errors.mess.message && (
							<span className='formfield__error'>{errors.mess.message}</span>
						)}
					</div>
					<div className='form__agree formfield'>
						<input
							type='checkbox'
							name='agree'
							id='agree'
							className='formfield__checkbox'
							onChange={() => setChecked(!checked)}
							checked={checked}
						/>
						<label htmlFor='agree' className='formfield__checkbox-label'>
							{t('form.agree1')}{' '}
							<a
								href='https://rusahar.ru/upload/policy/rs_policy.pdf'
								target='_blank'
								rel='noreferrer'
							>
								{t('form.agree2')}
							</a>
						</label>
					</div>
					<button
						type='submit'
						disabled={!checked}
						className={cn('btn btn_white', {
							btn_disabled: !checked
						})}
					>
						{t('form.send')}
					</button>
				</div>
			</form>

			{isShowing && (
				<Modal isVisible={isVisible} hide={tglPopup}>
					<h3 className='popup__title'>{t('form.confirm_title')}</h3>
					<p className='popup__desc'>{t('form.confirm_desc')}</p>
					<img
						src={`${IMG_URL}/icons/home/thx-popup.svg`}
						alt=''
						className='popup__decor-img'
					/>
				</Modal>
			)}
		</>
	);
};

export default ContactsForm;
