import { createSlice } from '@reduxjs/toolkit';

import { loadData as loadDataApi } from '../api';

const infoInitialState = {
	city: [],
	isLoading: false,
	isLoaded: false,
	error: null
};

export const slice = createSlice({
	name: 'map',
	initialState: infoInitialState,
	reducers: {
		getDataStart: state => {
			state.isLoading = true;
		},
		getDataSuccess: (state, { payload }) => {
			// eslint-disable-next-line
			// debugger;
			state.isLoading = false;
			// state.city = payload;
			state.city = payload.maps;
			state.isLoaded = true;
		},
		getDataFailure: (state, { payload }) => {
			state.error = payload;
			state.isLoading = false;
		}
	}
});

const { getDataStart, getDataSuccess, getDataFailure } = slice.actions;

export const loadMap = (path, lang) => async dispatch => {
	dispatch(getDataStart());
	try {
		const data = await loadDataApi(path, lang);
		dispatch(getDataSuccess(data));
	} catch (err) {
		dispatch(getDataFailure({ error: err.toString() }));
	}
};

export const selectMap = state => state.map;

export default slice.reducer;
