import React from 'react';
import { useSelector } from 'react-redux';

import PinSlider from '../PinSlider';
import { UPLOAD_IMG_URL } from '../../constants';
import { selectDeviceSize } from '../../reducers/deviceSlice';

import './index.scss';

const PinPopup = ({
	img,
	name,
	hide,
	url = null,
	slider = null,
	content = null
}) => {
	const wWidth = useSelector(selectDeviceSize);
	let fullName = 'Города и люди.';
	if (wWidth > 767) {
		fullName += `<br />${name}`;
	} else {
		fullName += ` ${name}`;
	}
	let newContent;

	if (content !== null) {
		const reg = /src=['"](\S+)['"]/gm;
		newContent = content.match(reg);
		newContent = content.replace(reg, `src=${UPLOAD_IMG_URL}$1`);
	}

	return (
		<div className='popup-map'>
			<div className='popup-map__title'>
				<img src={`${UPLOAD_IMG_URL}${img}`} alt={name} />
				<h3
					dangerouslySetInnerHTML={{
						__html: fullName
					}}
				></h3>
			</div>
			{url && (
				<div className='popup-map__iframe-wrap'>
					<iframe
						src={`https://www.youtube.com/embed/${url}?modestbranding=1;iv_load_policy=3;rel=0;enablejsapi=1&mute=1;showinfo=0`}
						width='100%'
						height='420'
						allow='accelerometer; encrypted-media; gyroscope; picture-in-picture'
						frameBorder='0'
						allowFullScreen
					></iframe>
				</div>
			)}
			{slider && slider.length > 1 && (
				<PinSlider className='popup-map__slider-wrap' slider={slider} />
			)}
			{slider && slider.length === 1 && (
				<div className='popup-map__simple-img'>
					<img src={`${UPLOAD_IMG_URL}${slider[0]}`} />
				</div>
			)}
			{content && (
				<>
					<div
						className='popup-map__content'
						dangerouslySetInnerHTML={{
							__html: newContent
						}}
					></div>
					<button className='popup-map__close btn' onClick={hide}>
						Вернуться к карте
					</button>
				</>
			)}
		</div>
	);
};

export default PinPopup;
