import { createSlice } from '@reduxjs/toolkit';

import { loadData as loadDataApi } from '../api';

const dataInitialState = {
	main: {},
	award: {},
	catalogSlider: [],
	interestingFacts: {},
	contacts: {},
	isLoading: false,
	isLoaded: false,
	error: null
};

export const slice = createSlice({
	name: 'data',
	initialState: dataInitialState,
	reducers: {
		getDataStart: state => {
			state.isLoading = true;
		},
		getDataSuccess: (state, { payload }) => {
			state.main = payload.main;
			state.award = payload.award;
			state.catalogSlider = payload.catalogSlider;
			state.interestingFacts = payload.interestingFacts;
			state.contacts.adres = payload.contacts.adres;
			state.contacts.phone = payload.contacts.phone;
			state.contacts.email = payload.contacts.email;
			state.isLoading = false;
			state.isLoaded = true;
		},
		getDataFailure: (state, { payload }) => {
			state.error = payload;
			state.isLoading = false;
		}
	}
});

const { getDataStart, getDataSuccess, getDataFailure } = slice.actions;

export const loadData = (path, lang) => async dispatch => {
	dispatch(getDataStart());
	try {
		const data = await loadDataApi(path, lang);
		dispatch(getDataSuccess(data));
	} catch (err) {
		dispatch(getDataFailure({ error: err.toString() }));
	}
};

export const selectData = state => state.data;

export default slice.reducer;
