import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { selectInfo, loadInfo } from '../../reducers/infoSlice';
import { selectDeviceSize, selectLang } from '../../reducers/deviceSlice';
import { IMG_URL } from '../../constants';

import Layout from '../../components/Layout';
import InfoItem from '../../components/InfoItem';

import './index.scss';

const InterestingFacts = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const lang = useSelector(selectLang);

	useEffect(() => {
		if (lang) {
			dispatch(loadInfo('info', lang));
		}
	}, [lang]);

	const data = useSelector(selectInfo);
	const wWidth = useSelector(selectDeviceSize);

	const { title, content, isLoaded } = data;

	return (
		<Layout>
			<Helmet>
				<title>{t('page_title.info')}</title>
			</Helmet>
			{isLoaded && (
				<section className='interesting-facts'>
					<div className='container'>
						<div className='row'>
							<div className='col-12'>
								<h1
									className='interesting-facts__title h2'
									dangerouslySetInnerHTML={{
										__html: title
									}}
								></h1>
								<ul className='interesting-facts__list info-list'>
									{content.map(child => {
										return <InfoItem key={child.id} {...child} />;
									})}
								</ul>
								{wWidth > 767 && (
									<img
										src={`${IMG_URL}/icons/info/pict-sugar.svg`}
										className='interesting-facts__decor interesting-facts__decor_cup'
									/>
								)}
								{wWidth > 1023 && (
									<img
										src={`${IMG_URL}/icons/info/spoon.svg`}
										className='interesting-facts__decor interesting-facts__decor_spoon'
									/>
								)}
							</div>
						</div>
					</div>
				</section>
			)}
		</Layout>
	);
};

export default InterestingFacts;
