import React from 'react';

import { IMG_URL } from '../../constants';

import './index.scss';

const ContactsBlock = ({ title, adres, phone, email }) => {
	return (
		<div className='contacts-block'>
			<h4
				className='contacts-block__title'
				dangerouslySetInnerHTML={{
					__html: title
				}}
			></h4>
			<div className='contacts-block__head contacts-item-head'>
				<img
					src={`${IMG_URL}/icons/contacts/pin.svg`}
					alt={title}
					className='contacts-item-head__img'
				/>
				<h4
					className='contacts-item-head__title contacts-item-head__title_ac'
					dangerouslySetInnerHTML={{
						__html: adres.title
					}}
				></h4>
			</div>
			<p
				className='contacts-block__desc desc'
				dangerouslySetInnerHTML={{
					__html: adres.desc
				}}
			></p>
			<div className='contacts-block__row block-row'>
				{!!phone.list && (
					<div className='block-row__col block-col'>
						<div className='block-col__head contacts-item-head'>
							<img
								src={`${IMG_URL}/icons/contacts/phone.svg`}
								alt=''
								className='contacts-item-head__img'
							/>
							<h4
								className='contacts-item-head__title contacts-item-head__title_ac'
								dangerouslySetInnerHTML={{
									__html: phone.title
								}}
							></h4>
						</div>
						{phone.list.map(tel => {
							return (
								<React.Fragment key={tel}>
									<a
										href={`tel:+${tel.replace(/\D/g, '')}`}
										className='block-col__connect link2 desc'
									>
										{tel}
									</a>
									<br />
								</React.Fragment>
							);
						})}
					</div>
				)}
				{!!email.list && (
					<div className='block-row__col block-col'>
						<div className='block-col__head contacts-item-head'>
							<img
								src={`${IMG_URL}/icons/contacts/mail.svg`}
								alt=''
								className='contacts-item-head__img'
							/>
							<h4
								className='contacts-item-head__title contacts-item-head__title_ac'
								dangerouslySetInnerHTML={{
									__html: email.title
								}}
							></h4>
						</div>
						{email.list.map(mail => {
							return (
								<React.Fragment key={mail}>
									<a
										href={`mailto:${mail}`}
										className='block-col__connect link2 desc'
									>
										{mail}
									</a>
									<br />
								</React.Fragment>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default ContactsBlock;
