import React from 'react';
import StocksSect from '../../components/StocksSect';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import './index.scss';

const Stocks = () => {
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>{t('page_title.stocks')}</title>
			</Helmet>
			<StocksSect page={true} />
		</>
	);
};

export default Stocks;
