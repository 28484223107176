import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { selectProduct, loadProduct } from '../../reducers/productSlice';
import CatalogSect from '../../components/CatalogSect';
import Layout from '../../components/Layout';

import './index.scss';
import { DOMAIN_URL, UPLOAD_IMG_URL } from '../../constants';

const Product = () => {
	const dispatch = useDispatch();
	const product = useSelector(selectProduct);
	const { i18n } = useTranslation();
	const history = useHistory();
	const { id } = useParams();
	const lang = localStorage.getItem('i18nextLng') || 'ru';
	const { id: productId, img, title, properties, isLoaded } = product;

	useEffect(() => {
		if (lang) {
			dispatch(loadProduct(`product&pid=${id}`, lang));
		}
	}, [lang, id]);

	useEffect(() => {
		if (isLoaded && productId === null) {
			const oldLang = localStorage.getItem('i18nextLng') || 'ru';
			i18n.changeLanguage(oldLang);
			history.push(`${DOMAIN_URL}${oldLang}/not-found`);
		}
	}, [productId, history, isLoaded]);

	return (
		<Layout>
			{isLoaded &&
				properties !== undefined &&
				!!Object.keys(properties).length && (
					<>
						<Helmet>
							<title>{title}</title>
						</Helmet>
						<section className='product'>
							<div className='container'>
								<div className='row'>
									<div className='col-12'>
										<h1
											className='product__title h2'
											dangerouslySetInnerHTML={{ __html: title }}
										></h1>
										<div className='product__wrap product-wrap'>
											<div className='product-wrap__prev'>
												<img src={`${UPLOAD_IMG_URL}${img}`} alt={title} />
											</div>
											<div className='product-wrap__tables'>
												<ul className='product-wrap__table table'>
													{!!properties.brand.title &&
														!!properties.brand.value && (
															<li className='table__item'>
																<div>
																	<span
																		dangerouslySetInnerHTML={{
																			__html: properties.brand.title
																		}}
																	></span>
																</div>
																<div>
																	<span
																		dangerouslySetInnerHTML={{
																			__html: properties.brand.value
																		}}
																	></span>
																</div>
															</li>
														)}
													{!!properties.vid.title && !!properties.vid.value && (
														<li className='table__item'>
															<div>
																<span
																	dangerouslySetInnerHTML={{
																		__html: properties.vid.title
																	}}
																></span>
															</div>
															<div>
																<span
																	dangerouslySetInnerHTML={{
																		__html: properties.vid.value
																	}}
																></span>
															</div>
														</li>
													)}
													{!!properties.obrabotka.title &&
														!!properties.obrabotka.value && (
															<li className='table__item'>
																<div>
																	<span
																		dangerouslySetInnerHTML={{
																			__html: properties.obrabotka.title
																		}}
																	></span>
																</div>
																<div>
																	<span
																		dangerouslySetInnerHTML={{
																			__html: properties.obrabotka.value
																		}}
																	></span>
																</div>
															</li>
														)}
													{!!properties.color.title &&
														!!properties.color.value && (
															<li className='table__item'>
																<div>
																	<span
																		dangerouslySetInnerHTML={{
																			__html: properties.color.title
																		}}
																	></span>
																</div>
																<div>
																	<span
																		dangerouslySetInnerHTML={{
																			__html: properties.color.value
																		}}
																	></span>
																</div>
															</li>
														)}
													{!!properties.type_puk.title &&
														!!properties.type_puk.value && (
															<li className='table__item'>
																<div>
																	<span
																		dangerouslySetInnerHTML={{
																			__html: properties.type_puk.title
																		}}
																	></span>
																</div>
																<div>
																	<span
																		dangerouslySetInnerHTML={{
																			__html: properties.type_puk.value
																		}}
																	></span>
																</div>
															</li>
														)}
													{!!properties.manufacture.title &&
														!!properties.manufacture.value && (
															<li className='table__item'>
																<div>
																	<span
																		dangerouslySetInnerHTML={{
																			__html: properties.manufacture.title
																		}}
																	></span>
																</div>
																<div>
																	<span
																		dangerouslySetInnerHTML={{
																			__html: properties.manufacture.value
																		}}
																	></span>
																</div>
															</li>
														)}
													{!!properties.capital.title &&
														!!properties.capital.value && (
															<li className='table__item'>
																<div>
																	<span
																		dangerouslySetInnerHTML={{
																			__html: properties.capital.title
																		}}
																	></span>
																</div>
																<div>
																	<span
																		dangerouslySetInnerHTML={{
																			__html: properties.capital.value
																		}}
																	></span>
																</div>
															</li>
														)}
													{!!properties.srok.title && !!properties.srok.value && (
														<li className='table__item'>
															<div>
																<span
																	dangerouslySetInnerHTML={{
																		__html: properties.srok.title
																	}}
																></span>
															</div>
															<div>
																<span
																	dangerouslySetInnerHTML={{
																		__html: properties.srok.value
																	}}
																></span>
															</div>
														</li>
													)}
												</ul>

												<div className='product-wrap__info product-info'>
													{!!properties.kall.title && (
														<h5
															className='product-info__title'
															dangerouslySetInnerHTML={{
																__html: properties.kall.title
															}}
														></h5>
													)}

													<ul className='product-wrap__table table'>
														{!!properties.uglevod.title &&
															!!properties.uglevod.value && (
																<li className='table__item'>
																	<div>
																		<span
																			dangerouslySetInnerHTML={{
																				__html: properties.uglevod.title
																			}}
																		></span>
																	</div>
																	<div>
																		<span
																			dangerouslySetInnerHTML={{
																				__html: properties.uglevod.value
																			}}
																		></span>
																	</div>
																</li>
															)}
														{!!properties.energy.title &&
															!!properties.energy.value && (
																<li className='table__item'>
																	<div>
																		<span
																			dangerouslySetInnerHTML={{
																				__html: properties.energy.title
																			}}
																		></span>
																	</div>
																	<div>
																		<span
																			dangerouslySetInnerHTML={{
																				__html: properties.energy.value
																			}}
																		></span>
																	</div>
																</li>
															)}
													</ul>
												</div>
												{!!properties.xranenie.title &&
													!!properties.xranenie.value && (
														<div className='product-wrap__info product-info'>
															<h5
																className='product-info__title'
																dangerouslySetInnerHTML={{
																	__html: properties.xranenie.title
																}}
															></h5>
															<p
																className='product-info__desc'
																dangerouslySetInnerHTML={{
																	__html: properties.xranenie.value
																}}
															></p>
														</div>
													)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>

						<CatalogSect className='catalog-sect_product' />
					</>
				)}
		</Layout>
	);
};

export default Product;
