import React from 'react';
import css from './index.module.scss';
import cn from 'classnames';
import { UPLOAD_IMG_URL } from '../../../constants';
import { ReactComponent as LinkIcon } from '../../../assets/img/icons/link.svg';

const SlideWrapper = ({ slide, children }) => {
	if (!slide.url) return children;

	return (
		<a
			href={slide.url}
			target='_blank'
			className={css.slide__link}
			rel='noreferrer'
		>
			{children}
			<LinkIcon className={css.slide__linkIcon} />
		</a>
	);
};

const Slide = ({ data, className }) => {
	return (
		<div className={cn(css.slides, className)}>
			{data.map(slide => (
				<div className={cn(css.slides__slide, css.slide)} key={slide.id}>
					<SlideWrapper slide={slide}>
						<div className={css.slide__imgContainer}>
							<img
								src={`${UPLOAD_IMG_URL}${slide.src}`}
								alt={slide.alt}
								className={css.slide__img}
							/>
						</div>
					</SlideWrapper>
				</div>
			))}
		</div>
	);
};

export default Slide;
