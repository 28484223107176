import React from 'react';
import css from './index.module.scss';
import cn from 'classnames';

const Preloader = ({ className }) => {
	return (
		<div className={cn(className, css.preloader)}>
			<div className={css.preloader__spiner}></div>
		</div>
	);
};

export default Preloader;
