import React from 'react';
import cn from 'classnames';

import { UPLOAD_IMG_URL } from '../../constants';

import './index.scss';

const CityItem = ({
	className,
	handlePickPoint,
	id,
	img,
	name,
	active = false
}) => {
	return (
		<li className={`${className} sity-item`}>
			<button
				onClick={e => handlePickPoint(e, id)}
				className={cn('sity-btn', {
					'sity-btn_active': active
				})}
			>
				<img
					src={`${UPLOAD_IMG_URL}${img}`}
					alt={name}
					className='sity-btn__prev'
				/>
				<span className='sity-btn__name desc'>{name}</span>
			</button>
		</li>
	);
};

export default CityItem;
