import React from 'react';

import { IMG_URL } from '../../constants';

import './index.scss';

const ContactsItem = ({ type, title, list = [], desc = '' }) => {
	return (
		<li className='contacts-info__item contacts-item'>
			<div className='contacts-item__head contacts-item-head'>
				{type === 'adres' && <img src={`${IMG_URL}/icons/contacts/pin.svg`} alt={title} className='contacts-item-head__img' />}
				{type === 'phone' && <img src={`${IMG_URL}/icons/contacts/phone.svg`} alt={title} className='contacts-item-head__img' />}
				{type === 'email' && <img src={`${IMG_URL}/icons/contacts/mail.svg`} alt={title} className='contacts-item-head__img' />}
				<p
					className='contacts-item-head__title h4'
					dangerouslySetInnerHTML={{
						__html: title
					}}
				></p>
			</div>
			{!!desc && type === 'adres' && (
				<p
					className='contacts-item__desc desc'
					dangerouslySetInnerHTML={{
						__html: desc
					}}
				></p>
			)}
			{!!list && type === 'phone' && (
				<>
					{list.map(el => {
						return (
							<React.Fragment key={el}>
								<a
									href={`tel:+${el.replace(/\D/g, '')}`}
									className='contacts-item__connect desc link2'
								>
									{el}
								</a>
								<br />
							</React.Fragment>
						);
					})}
				</>
			)}
			{!!list && type === 'email' && (
				<>
					{list.map(el => {
						return (
							<React.Fragment key={el}>
								<a
									key={el}
									href={`mailto:${el}`}
									className='contacts-item__connect desc link2'
								>
									{el}
								</a>
								<br />
							</React.Fragment>
						);
					})}
				</>
			)}
		</li>
	);
};

export default ContactsItem;
