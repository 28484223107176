import React from 'react';
import { useSelector } from 'react-redux';

import StockCard from '../StockCard';
import { selectStocks } from '../../reducers/stocksSlice';

import './index.scss';

const StocksSect = ({page = false }) => {
	const stocksData = useSelector(selectStocks);
	const length = stocksData.stocks !== undefined && stocksData.stocks.length > 1;

	return (
		<>
			{stocksData.isLoaded && length && (
				<section className='stocks-sect'>
					<div className='container'>
						<div className='row'>
							<div className='col-12'>
								{!page ? (
								<h2
									className='stocks-sect__title'
									dangerouslySetInnerHTML={{
										__html: stocksData.title
									}}
								></h2>
								): (
								<h1
									className='stocks-sect__title h2'
									dangerouslySetInnerHTML={{
										__html: stocksData.title
									}}
								></h1>
								)}
							</div>
							{stocksData.stocks.map(el => {
								return (
									<div
										className='stocks-sect__card stocks-card col-12 col-sm-6'
										key={el.id}
									>
										<StockCard {...el} />
									</div>
								);
							})}
						</div>
					</div>
				</section>
			)}
		</>
	);
};

export default StocksSect;
