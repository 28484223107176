import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { selectCatalog, loadCatalog } from '../../reducers/catalogSlice';
import { selectDeviceSize, selectLang } from '../../reducers/deviceSlice';
import { IMG_URL, UPLOAD_IMG_URL, DOMAIN_URL } from '../../constants';
import Layout from '../../components/Layout';

import './index.scss';

import { ReactComponent as TopLeftCube } from '../../assets/img/icons/sugar-anim/top-left-cube.svg';
import { ReactComponent as TopRightCube } from '../../assets/img/icons/sugar-anim/top-right-cube.svg';
import { ReactComponent as BotLeftCube } from '../../assets/img/icons/sugar-anim/bot-left-cube.svg';
import { ReactComponent as BotRightCube } from '../../assets/img/icons/sugar-anim/bot-right-cube.svg';
import { ReactComponent as Sand2 } from '../../assets/img/icons/sugar-anim/sand2.svg';
import { ReactComponent as Crumbs } from '../../assets/img/icons/sugar-anim/crumbs.svg';

const Catalog = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const wWidth = useSelector(selectDeviceSize);
	const lang = useSelector(selectLang);

	useEffect(() => {
		if (lang) {
			dispatch(loadCatalog('catalog', lang));
		}
	}, [lang]);

	const catalogData = useSelector(selectCatalog);
	const { isLoaded, main, catalog, title } = catalogData;

	return (
		<Layout>
			<Helmet>
				<title>{t('page_title.catalog')}</title>
			</Helmet>
			{isLoaded && (
				<section className='catalog'>
					<div className='container'>
						<div className='row'>
							<div className='col-12'>
								<h1
									className='catalog__title h2'
									dangerouslySetInnerHTML={{ __html: title }}
								></h1>
							</div>
						</div>
					</div>
					{!!Object.keys(main).length && (
						<div className='catalog__banner catalog-banner'>
							<div className='container'>
								<div className='row'>
									<div className='col-12'>
										<div className='catalog-banner__content'>
											<p
												className='catalog-banner__title'
												dangerouslySetInnerHTML={{ __html: main.title }}
											></p>
											<h3
												className='catalog-banner__desc'
												dangerouslySetInnerHTML={{ __html: main.desc }}
											></h3>
										</div>
									</div>
								</div>
							</div>
							{wWidth > 767 ? (
								<img
									src={`${IMG_URL}/content/calog-prev.png`}
									alt={main.title}
									srcSet={`${IMG_URL}/content/calog-prev@2x.png 2x, ${IMG_URL}/content/calog-prev@3x.png 3x`}
									className='catalog-banner__img'
								/>
							) : (
								<img
									src={`${IMG_URL}/content/calog-prev-xs.png`}
									alt={main.title}
									srcSet={`${IMG_URL}/content/calog-prev-xs@2x.png 2x, ${IMG_URL}/content/calog-prev-xs@3x.png 3x`}
									className='catalog-banner__img'
								/>
							)}
							<Link
								to={`${DOMAIN_URL}${lang}/product/${main.link}`}
								className='catalog-banner__link btn'
							>
								{t('more')}
							</Link>
						</div>
					)}

					<div className='container'>
						<div className='row'>
							{catalog.map(
								({ id, title, img, srcSet, code, img_anim = '' }) => {
									return (
										<Link
											className='catalog__card catalog-card catalog-slide col-12 col-sm-6 col-md-4 col-lg-4'
											key={id}
											to={`${DOMAIN_URL}${lang}/product/${code}`}
										>
											<div className='catalog-slide__img-wrap'>
												<img
													src={`${UPLOAD_IMG_URL}${img}`}
													srcSet={srcSet}
													alt={title}
													className='catalog-card__img'
												/>
												{img_anim === 'cube' && (
													<div className='catalog-slide__decor catalog-slide__decor_cube-top'>
														<TopLeftCube />
														<TopRightCube />
													</div>
												)}
												{img_anim === 'cube2' && (
													<div className='catalog-slide__decor catalog-slide__decor_cube-bot'>
														<BotLeftCube />
														<BotRightCube />
													</div>
												)}
												{img_anim === 'sand' && (
													<div className='catalog-slide__decor catalog-slide__decor_sand'>
														<Sand2 />
														<Crumbs />
													</div>
												)}
											</div>
											<p
												className='catalog-card__title h5'
												dangerouslySetInnerHTML={{ __html: title }}
											></p>
										</Link>
									);
								}
							)}
						</div>
					</div>
				</section>
			)}
		</Layout>
	);
};

export default Catalog;
