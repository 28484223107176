import axios from 'axios';

/* eslint-disable  */
const _apiBase = process.env.REACT_APP_API_URL;

const _apiBaseForm = process.env.REACT_APP_API_URL_FORM;
/* eslint-enable  */

export const loadData = async (path, lang) => {
	let result;
	if (lang !== 'ru') {
		result = await axios(`${_apiBase}${path}&lang=${lang}`);
	} else {
		result = await axios(`${_apiBase}${path}`);
	}
	return result.data;
};

export const sendFormData = async data => {
	return await axios
		.post(
			`${_apiBaseForm}`,
			`name=${data.name}&email=${data.email}&mess=${data.mess}`
		)
		.then(res => {
			return res.data;
		});
};
