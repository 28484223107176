import { createSlice } from '@reduxjs/toolkit';

import { loadData as loadDatatApi } from '../api';

const leopardusInitialState = {
	titleOne: '',
	titleTwo: '',
	descOne: '',
	descTwo: '',
	descThree: '',
	descFour: '',
	slides: [],
	mainVideo: null,
	isLoading: false,
	isLoaded: false,
	error: null
};

export const slice = createSlice({
	name: 'leopardus',
	initialState: leopardusInitialState,
	reducers: {
		getDataStart: state => {
			state.isLoading = true;
		},
		getDataSuccess: (state, { payload }) => {
			console.log(payload);
			state.titleOne = payload.leopardus.titleOne;
			state.titleTwo = payload.leopardus.titleTwo;
			state.descOne = payload.leopardus.descOne;
			state.descTwo = payload.leopardus.descTwo;
			state.descThree = payload.leopardus.descThree;
			state.descFour = payload.leopardus.descFour;
			state.slides = payload.leopardus.slides;
			state.mainVideo = payload.leopardus.mainVideo;
			state.isLoading = false;
			state.isLoaded = true;
		},
		getDataFailure: (state, { payload }) => {
			state.isLoading = false;
			state.error = payload;
		}
	}
});

const { getDataStart, getDataSuccess, getDataFailure } = slice.actions;

export const loadLeopardus = (path, lang) => async dispatch => {
	dispatch(getDataStart());
	try {
		const data = await loadDatatApi(path, lang);
		dispatch(getDataSuccess(data));
	} catch (err) {
		dispatch(getDataFailure({ error: err.toString() }));
	}
};

export const selectLeopardus = state => state.leopardus;

export default slice.reducer;
