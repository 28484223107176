import { createSlice } from '@reduxjs/toolkit';

import { loadData as loadDataApi } from '../api';

const contactsInitialState = {
	title: '',
	contacts: [],
	isLoading: false,
	isLoaded: false,
	error: null
};

export const slice = createSlice({
	name: 'contacts',
	initialState: contactsInitialState,
	reducers: {
		getDataStart: state => {
			state.isLoading = true;
		},
		getDataSuccess: (state, { payload }) => {
			state.title = payload.title;
			state.contacts = payload.contacts;
			state.isLoading = false;
			state.isLoaded = true;
		},
		getDataFailure: (state, { payload }) => {
			state.error = payload;
			state.isLoading = false;
		}
	}
});

const { getDataStart, getDataSuccess, getDataFailure } = slice.actions;

export const loadContacts = (path, lang) => async dispatch => {
	dispatch(getDataStart());
	try {
		const data = await loadDataApi(path, lang);
		dispatch(getDataSuccess(data));
	} catch (err) {
		dispatch(getDataFailure({ error: err.toString() }));
	}
};

export const selectContacts = state => state.contacts;

export default slice.reducer;
