import { createSlice } from '@reduxjs/toolkit';

import { loadData as loadDataApi } from '../api';

const stockInitialState = {
	title: '',
	img: null,
	catalog: [],
	isLoading: false,
	isLoaded: false,
	error: null
};

export const slice = createSlice({
	name: 'stock',
	initialState: stockInitialState,
	reducers: {
		getDataStart: state => {
			state.isLoading = true;
		},
		getDataSuccess: (state, { payload }) => {
			state.title = payload.title;
			state.img = payload.img;
			state.stock = payload.catalog;
			state.isLoading = false;
			state.isLoaded = true;
		},
		getDataFailure: (state, { payload }) => {
			state.isLoading = false;
			state.error = payload;
		}
	}
});

const { getDataStart, getDataSuccess, getDataFailure } = slice.actions;

export const loadStock = (path, lang) => async dispatch => {
	dispatch(getDataStart());
	try {
		const data = await loadDataApi(path, lang);
		dispatch(getDataSuccess(data));
	} catch (err) {
		dispatch(getDataFailure({ error: err.toString() }));
	}
};

export const selectStock = state => state.stock;

export default slice.reducer;
