import React, { useEffect } from 'react';
import css from './index.module.scss';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
	wherebuySelector,
	loadWherebuy
} from '../../../../reducers/wherebuySlice';
import { selectLang } from '../../../../reducers/deviceSlice';

import Tabs from '../../../../components/Tabs';
import WherebuySlider from '../../../../components/WherebuySlider';
import Preloader from '../../../../components/Preloader';

const Wherebuy = ({ className, ...props }) => {
	const dispatch = useDispatch();
	const { data, isLoading, isLoaded, error } = useSelector(wherebuySelector);
	const lang = useSelector(selectLang);

	useEffect(() => {
		if (lang) {
			dispatch(loadWherebuy(lang));
		}
	}, [dispatch, lang]);

	if (isLoading || (!data && !error)) {
		return (
			<div {...props}>
				<Preloader />
			</div>
		);
	}

	if (isLoaded && error) {
		return (
			<div className={css.error} {...props}>
				{error}
			</div>
		);
	}

	return (
		<section className={cn(css.wherebuy, className)} {...props}>
			<div className='container'>
				<div className='row'>
					<div className='col-12'>
						<h2 className={cn(css.wherebuy__title, 'h2')}>{data.title}?</h2>

						<div className={css.wherebuy__content}>
							<Tabs
								data={[
									{
										id: 'offshop',
										label: data.offshop.label,
										content: <WherebuySlider data={data.offshop.data} />
									},
									{
										id: 'onshop',
										label: data.onshop.label,
										content: <WherebuySlider data={data.onshop.data} />
									}
								]}
								className={css.wherebuy__tabs}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Wherebuy;
