import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { loadData as loadDataApi } from '../api';

const initialState = {
	data: null,
	isLoading: false,
	isLoaded: false,
	error: null
};

/*
responce = {
	title: ""
	offshop: {label: '', data: []}
	onshop: {label: '', data: []}
}
*/

export const wherebuySlice = createSlice({
	name: 'wherebuy',
	initialState: initialState,
	reducers: {
		getWherebuy: state => {
			state.isLoading = true;
		},
		getWherebuySuccess: (state, { payload }) => {
			state.isLoading = false;
			state.isLoaded = true;
			state.error = null;
			state.data = payload;
		},
		getWherebuyFailure: (state, { payload }) => {
			state.isLoading = false;
			state.isLoaded = true;
			state.error = payload;
		}
	}
});

const { getWherebuy, getWherebuySuccess, getWherebuyFailure } =
	wherebuySlice.actions;

export const loadWherebuy = lang => async dispatch => {
	dispatch(getWherebuy());

	try {
		const data = await loadDataApi('shop', lang);

		if (typeof data === 'string') {
			dispatch(getWherebuyFailure(data));
		} else {
			dispatch(getWherebuySuccess(data));
		}
	} catch (err) {
		dispatch(getWherebuyFailure(err.toString()));
	}
};

export const wherebuySelector = state => state.wherebuy;

export default wherebuySlice.reducer;
