import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { IMG_URL, DOMAIN_URL } from '../../constants';
import Layout from '../../components/Layout';

import './index.scss';

const NotFound = () => {
	const { t } = useTranslation();
	const { lang } = useParams();
	const currentlang = lang || localStorage.getItem('i18nextLng') || 'ru';

	return (
		<Layout>
			<Helmet>
				<title>{t('page_title.not_found')}</title>
			</Helmet>
			<section className='not-found'>
				<img
					src={`${IMG_URL}/404/404.png`}
					srcSet={`${IMG_URL}/404/404@2x.png 2x, ${IMG_URL}/404/404@3x.png 3x`}
					alt=''
					className='not-found__img'
				/>
				<h4 className='not-found__title'>{t('error.title')}</h4>
				<p className='not-found__desc'>{t('error.desc')}</p>
				<Link className='btn' to={`${DOMAIN_URL}${currentlang}`}>
					{t('error.link')}
				</Link>
			</section>
		</Layout>
	);
};

export default NotFound;
