import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swiper from 'react-id-swiper';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import Modal from '../../components/Modal';

import { loadLeopardus, selectLeopardus } from '../../reducers/leopardusSlice';
import { selectDeviceSize, selectLang } from '../../reducers/deviceSlice';
import { IMG_URL, UPLOAD_IMG_URL } from '../../constants';

import { ReactComponent as VideoIcon } from '../../assets/img/icons/video.svg';

import 'swiper/swiper.scss';
import './index.scss';
import Layout from '../../components/Layout';

const Leopardus = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const wWidth = useSelector(selectDeviceSize);
	const ref = useRef(null);

	const lang = useSelector(selectLang);
	useEffect(() => {
		if (lang) {
			dispatch(loadLeopardus('leopard', lang));
		}
	}, [lang]);

	const leopardus = useSelector(selectLeopardus);

	const {
		titleOne,
		titleTwo,
		isLoaded,
		descOne,
		descTwo,
		descThree,
		descFour,
		slides,
		mainVideo
	} = leopardus;

	useEffect(() => {
		console.log(slides);
	});

	const [activeSlideId, setActiveSlideId] = useState(0);
	const [isShowing, setIsShowing] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [isShowingKv, setIsShowingKv] = useState(false);
	const [isVisibleKv, setIsVisibleKv] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);

	const params = {
		loop: false,
		grabCursor: true,
		slidesPerView: 'auto',
		spaceBetween: 10,
		preventClicks: false,
		preventClicksPropagation: false,
		allowTouchMove: true,
		breakpoints: {
			768: {
				spaceBetween: 20
			},
			1280: {
				slidesPerView: 3,
				spaceBetween: 20
			},
			1440: {
				slidesPerView: 4,
				allowTouchMove: false,
				spaceBetween: 24
			}
		},
		on: {
			init: function () {
				if (slides.length < 5) {
					document.querySelector('.top-section__buttons').style.display =
						'none';
				}
			}
		}
	};

	const goNext = () => {
		if (ref.current !== null && ref.current.swiper !== null) {
			ref.current.swiper.slideNext();
		}
	};

	const goPrev = () => {
		if (ref.current !== null && ref.current.swiper !== null) {
			ref.current.swiper.slidePrev();
		}
	};

	const tglPopupKv = () => {
		if (isShowingKv) {
			setIsVisibleKv(false);
			setTimeout(() => {
				setIsShowingKv(false);
				setIsPlaying(false);
			}, 300);
		} else {
			setIsShowingKv(true);
			setTimeout(() => {
				setIsVisibleKv(true);
			}, 0);
		}
	};
	const tglPopup = () => {
		if (isShowing) {
			setIsVisible(false);
			setTimeout(() => {
				setIsShowing(false);
				setIsPlaying(false);
			}, 300);
		} else {
			setIsShowing(true);
			setTimeout(() => {
				setIsVisible(true);
			}, 0);
		}
	};

	const openPopup = (evt, id) => {
		evt.preventDefault();
		setActiveSlideId(id);
		tglPopup();
	};

	return (
		<Layout>
			<Helmet>
				<title>{t('page_title.leopardus')}</title>
			</Helmet>
			{isLoaded && (
				<>
					<section className='leopardus__top'>
						<div className='top-section'>
							<div className='container'>
								<div className='top-section__wrapper'>
									<div className='top-section__left'>
										<div className='top-section__logo'>
											<img
												src={`${IMG_URL}/leopardus/logo.svg`}
												// srcSet={`${IMG_URL}/leopardus/logo@2x.png 2x, ${IMG_URL}/leopardus/logo@3x.png 3x`}
												alt=''
											/>
										</div>

										<div className='top-section__content'>
											<h1
												className='top-section__title h1'
												dangerouslySetInnerHTML={{ __html: titleOne }}
											></h1>

											<div
												className='top-section__focused-text'
												dangerouslySetInnerHTML={{ __html: descOne }}
											></div>

											<div
												className='top-section__text'
												dangerouslySetInnerHTML={{ __html: descTwo }}
											></div>
										</div>
									</div>

									<div className='top-section__right'>
										<div className='top-section__kv'>
											{wWidth < 768 && (
												<img
													src={`${IMG_URL}/leopardus/kv360.png`}
													srcSet={`${IMG_URL}/leopardus/kv360@2x.png 2x, ${IMG_URL}/leopardus/kv360@3x.png 3x`}
													alt=''
												/>
											)}
											{wWidth >= 768 && (
												<img
													src={`${IMG_URL}/leopardus/kv.png`}
													srcSet={`${IMG_URL}/leopardus/kv@2x.png 2x, ${IMG_URL}/leopardus/kv@3x.png 3x`}
													alt=''
												/>
											)}

											{mainVideo && (
												<a className='top-section__kv-btn' onClick={tglPopupKv}>
													<i>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='35'
															height='48'
															viewBox='0 0 35 48'
															fill='none'
														>
															<g clipPath='url(#clip0_217_61775)'>
																<path
																	d='M32.4594 20.4781L5.8964 6.17498C3.23148 4.74002 -1.60428e-07 6.67017 -2.92729e-07 9.69687L-1.54315e-06 38.3031C-1.67545e-06 41.3298 3.23148 43.26 5.8964 41.825L32.4594 27.5219C35.2642 26.0116 35.2642 21.9884 32.4594 20.4781Z'
																	fill='#F9F6F6'
																/>
															</g>
															<defs>
																<clipPath id='clip0_217_61775'>
																	<rect width='35' height='48' fill='white' />
																</clipPath>
															</defs>
														</svg>
													</i>
													<span>Смотреть видео</span>
												</a>
											)}
										</div>
									</div>
								</div>
								<div
									className='top-section__text top-section__text_mobile'
									dangerouslySetInnerHTML={{ __html: descTwo }}
								></div>
							</div>

							<div className='top-section__slider'>
								<div className='top-section__slider-wrap'>
									<Swiper
										ref={ref}
										{...params}
										containerClass='leopardus-slider container'
										onSlideChange={() => {
											document.querySelectorAll('video').forEach(video => {
												video.pause();
											});
										}}
									>
										{slides.map((slide, index) => {
											return (
												<div
													key={slide.id}
													className='leopardus-slider__slide leopardus-slide'
												>
													<a
														href='#'
														className='leopardus-slide__link'
														onClick={evt => openPopup(evt, index)}
													>
														<div className='leopardus-slide__image'>
															<img
																src={`${UPLOAD_IMG_URL}${
																	slide.img || slide.stub
																}`}
																alt={slide.title}
															/>
														</div>
														<div className='leopardus-slide__content'>
															<div className='leopardus-slide__date'>
																{slide.date}
															</div>
															{slide.video && (
																<div className='leopardus-slide__counter'>
																	<VideoIcon />
																</div>
															)}
														</div>
													</a>
												</div>
											);
										})}
									</Swiper>
									<div className='top-section__buttons'>
										<button
											className='top-section__button sl-btn-alt'
											onClick={goPrev}
										>
											<i className='sl-btn-alt__icon'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='22'
													height='22'
													viewBox='0 0 22 22'
													fill='none'
												>
													<path
														d='M15.6104 2L7 10.6104L15.6104 19.2208'
														stroke='#DC4235'
														strokeWidth='2'
														strokeLinecap='round'
													/>
												</svg>
											</i>
										</button>
										<button
											className='top-section__button sl-btn-alt'
											onClick={goNext}
										>
											<i className='sl-btn-alt__icon'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='22'
													height='22'
													viewBox='0 0 22 22'
													fill='none'
												>
													<path
														d='M7.38961 2L16 10.6104L7.38961 19.2208'
														stroke='#DC4235'
														strokeWidth='2'
														strokeLinecap='round'
													/>
												</svg>
											</i>
										</button>
									</div>
								</div>
							</div>

							<div className='top-section__decor'>
								<div className='top-section__decor-item'>
									<img src={`${IMG_URL}/leopardus/leaf2.png`} alt='' />
								</div>
								<div className='top-section__decor-item top-section__decor-item_left'>
									<img src={`${IMG_URL}/leopardus/leaf.png`} alt='' />
								</div>
							</div>
						</div>
					</section>

					<section className='leopardus__bottom'>
						<div className='bottom-section'>
							<div className='container'>
								<div className='bottom-section__wrapper'>
									<div className='bottom-section__image'>
										<img
											src={`${IMG_URL}/leopardus/leomask.png`}
											srcSet={`${IMG_URL}/leopardus/leomask@2x.png 2x, ${IMG_URL}/leopardus/leomask@3x.png 3x`}
											alt=''
										/>
										<div className='bottom-section__steps'>
											<img src={`${IMG_URL}/leopardus/steps.svg`} />
										</div>
									</div>
									<div className='bottom-section__content'>
										<h2
											className='bottom-section__title h2'
											dangerouslySetInnerHTML={{ __html: titleTwo }}
										></h2>
										<div
											className='bottom-section__text'
											dangerouslySetInnerHTML={{ __html: descThree }}
										></div>
										<div
											className='bottom-section__text'
											dangerouslySetInnerHTML={{ __html: descFour }}
										></div>
										<div className='bottom-section__button'>
											<a
												href='https://leopard-land.ru/'
												className='btn'
												target='_blank'
												rel='noreferrer'
											>
												{t('more')}
											</a>
										</div>
									</div>
								</div>
							</div>

							<div className='bottom-section__background'>
								<div className='bottom-section__gray'>
									<img
										src={`${IMG_URL}/leopardus/bcg.png`}
										srcSet={`${IMG_URL}/leopardus/bcg@2x.png 2x, ${IMG_URL}/leopardus/bcg@3x.png 3x`}
										alt=''
									/>
								</div>
								<div className='bottom-section__gray2'>
									<img
										src={`${IMG_URL}/leopardus/bc2g.png`}
										srcSet={`${IMG_URL}/leopardus/bcg2@2x.png 2x, ${IMG_URL}/leopardus/bcg2@3x.png 3x`}
										alt=''
									/>
								</div>
								<div className='bottom-section__white-leaf'>
									<img
										src={`${IMG_URL}/leopardus/whiteleaf.png`}
										srcSet={`${IMG_URL}/leopardus/whiteleaf@2x.png 2x, ${IMG_URL}/leopardus/whiteleaf@3x.png 3x`}
										alt=''
									/>
								</div>
								<div className='bottom-section__green'>
									<img
										src={`${IMG_URL}/leopardus/green.png`}
										srcSet={`${IMG_URL}/leopardus/green@2x.png 2x, ${IMG_URL}/leopardus/green@3x.png 3x`}
										alt=''
									/>
								</div>
								<div className='bottom-section__upper-leaf'>
									<img
										src={`${IMG_URL}/leopardus/upperleaf.png`}
										srcSet={`${IMG_URL}/leopardus/upperleaf@2x.png 2x, ${IMG_URL}/leopardus/upperleaf@3x.png 3x`}
										alt=''
									/>
								</div>
							</div>
						</div>
					</section>

					{isLoaded && isShowing && (
						<Modal
							isVisible={isVisible}
							modifierPopupClass='popup_leopardus'
							hide={tglPopup}
						>
							<h1 className='popup__title'>{slides[activeSlideId].title}</h1>
							<div className='popup__date'>
								<span>{slides[activeSlideId].date}</span>
							</div>
							{slides[activeSlideId].video && (
								<div
									key={slides[activeSlideId].video}
									className='pin-slider__video pin-slider__video_leopardus'
								>
									<video
										autoPlay
										playsInline
										muted
										controls
										src={`${UPLOAD_IMG_URL}${slides[activeSlideId].video}`}
									></video>
									{/* <div
										className={classNames('pin-slider__video-stub', {
											'pin-slider__video-stub_hidden': isPlaying
										})}
										onClick={() => setIsPlaying(true)}
									>
										<img
											src={`${UPLOAD_IMG_URL}${slides[activeSlideId].stub}`}
											alt=''
										/>
										<i>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='48'
												height='48'
												viewBox='0 0 48 48'
												fill='none'
											>
												<path
													d='M38.4594 20.4781L11.8964 6.17498C9.23148 4.74002 6 6.67017 6 9.69687L6 38.3031C6 41.3298 9.23148 43.26 11.8964 41.825L38.4594 27.5219C41.2642 26.0116 41.2642 21.9884 38.4594 20.4781Z'
													fill='#F9F6F6'
												/>
											</svg>
										</i>
									</div> */}
								</div>
							)}
							{slides[activeSlideId].img && !slides[activeSlideId].video && (
								<div
									key={slides[activeSlideId].img}
									className='pin-slider__image'
								>
									<img src={`${UPLOAD_IMG_URL}${slides[activeSlideId].img}`} />
								</div>
							)}
							{slides[activeSlideId].desc && (
								<div
									dangerouslySetInnerHTML={{
										__html: slides[activeSlideId].desc
									}}
									key={slides[activeSlideId].desc}
									className='pin-slider__text'
								></div>
							)}
						</Modal>
					)}

					{mainVideo && isLoaded && isShowingKv && (
						<Modal
							isVisible={isVisibleKv}
							modifierPopupClass='popup_leopardus-large'
							hide={tglPopupKv}
						>
							<div
								key={mainVideo}
								className='pin-slider__video pin-slider__video_leopardus'
							>
								<video
									autoPlay
									playsInline
									muted
									controls
									src={`${UPLOAD_IMG_URL}${mainVideo.src}`}
								></video>
								{/* <div
									className={classNames('pin-slider__video-stub', {
										'pin-slider__video-stub_hidden': isPlaying
									})}
									onClick={() => setIsPlaying(true)}
								></div> */}
							</div>
						</Modal>
					)}
				</>
			)}
		</Layout>
	);
};

export default Leopardus;
