import React from 'react';
import { Link, useParams } from 'react-router-dom';

import './index.scss';

import { DOMAIN_URL, UPLOAD_IMG_URL } from '../../constants';

import { ReactComponent as TopLeftCube } from '../../assets/img/icons/sugar-anim/top-left-cube.svg';
import { ReactComponent as TopRightCube } from '../../assets/img/icons/sugar-anim/top-right-cube.svg';
import { ReactComponent as BotLeftCube } from '../../assets/img/icons/sugar-anim/bot-left-cube.svg';
import { ReactComponent as BotRightCube } from '../../assets/img/icons/sugar-anim/bot-right-cube.svg';
import { ReactComponent as Sand2 } from '../../assets/img/icons/sugar-anim/sand2.svg';
import { ReactComponent as Crumbs } from '../../assets/img/icons/sugar-anim/crumbs.svg';

const CatalogSlide = ({ code, img, srcSet, name, img_anim = '' }) => {
	const { lang } = useParams();
	return (
		<Link to={`${DOMAIN_URL}${lang}/product/${code}`} className='catalog-slide'>
			<div className='catalog-slide__img-wrap'>
				<img
					src={`${UPLOAD_IMG_URL}${img}`}
					srcSet={srcSet}
					alt={name}
					className='catalog-slide__img'
				/>
				{img_anim === 'cube' && (
					<div className='catalog-slide__decor catalog-slide__decor_cube-top'>
						<TopLeftCube />
						<TopRightCube />
					</div>
				)}
				{img_anim === 'cube2' && (
					<div className='catalog-slide__decor catalog-slide__decor_cube-bot'>
						<BotLeftCube />
						<BotRightCube />
					</div>
				)}
				{img_anim === 'sand' && (
					<div className='catalog-slide__decor catalog-slide__decor_sand'>
						<Sand2 />
						<Crumbs />
					</div>
				)}
			</div>
			<p
				className='catalog-slide__name h5'
				dangerouslySetInnerHTML={{ __html: name }}
			></p>
		</Link>
	);
};

export default CatalogSlide;
