const checkSlash = url => {
	if (!!url && typeof url === 'string') {
		return url.substr(-1) !== '/' ? url + '/' : url;
	}
	return undefined;
};

const DOMAIN_URL = checkSlash(process.env.REACT_APP_DOMAIN) || '/';
const FOLDER_URL = checkSlash(process.env.PUBLIC_URL) || '/';
const UPLOAD_IMG_URL = process.env.REACT_APP_UPLOAD_IMG_URL;
const IMG_URL = `${FOLDER_URL}img`;

export { UPLOAD_IMG_URL, IMG_URL, FOLDER_URL, DOMAIN_URL };
