import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IMG_URL, DOMAIN_URL } from '../../constants';
import { selectDeviceSize } from '../../reducers/deviceSlice';

import './index.scss';

const Footer = () => {
	const wWidth = useSelector(selectDeviceSize);
	const { t } = useTranslation();
	const { lang } = useParams();

	return (
		<footer className='footer'>
			<div className='footer__top'>
				<div className='container'>
					<div className='row'>
						<div className='col-12 col-sm-5 col-lg-7'>
							<Link to={`${DOMAIN_URL}${lang}`} className='footer__logo'>
								<img src={`${IMG_URL}/icons/logo-ru-sugar.svg`} />
							</Link>
						</div>
						<div className='col-sm-7 col-md-6 col-lg-5'>
							<div className='footer__contacts footer-contacts'>
								<p className='footer-contacts__desc'>{t('footer.desc')}</p>
								<div className='footer-contacts__connect'>
									<a href='tel:84953631661'>8 (495) 363-16-61</a> <br />
									<a href='mailto:rusagro@rusagrogroup.ru'>
										rusagro@rusagrogroup.ru
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='footer__bottom'>
				<div className='container'>
					<div className='row'>
						<div className='col-12 col-sm-5 order-2 order-sm-0 col-lg-7'>
							<div className='footer__copywrite'>
								<p>
									{t('footer.reserved1')}
									{wWidth < 1279 && <br />}
									{t('footer.reserved2')}
								</p>
							</div>
						</div>
						<div className='col-md-6 col-lg-5 col-6 col-sm-7 col-12 order-1'>
							<div className='footer__creators'>
								<div className='footer__creator-title'>
									<p>{t('footer.reserved3')}</p>
								</div>
								<div className='footer__creator-logo'>
									<a
										href='https://grechka.digital'
										className=''
										target='_blank'
										rel='noreferrer'
									>
										<img
											src={`${IMG_URL}/icons/logo-grechka.svg`}
											alt='Гречка Диджитал.'
										/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
