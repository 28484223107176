import React, { useState, useRef, useEffect } from 'react';
import css from './index.module.scss';
import cn from 'classnames';
import Fade from '../Fade';

const Tabs = ({ data, activeTabIndex = 0, className }) => {
	const [activeTab, setActiveTab] = useState(data[activeTabIndex]);
	const [visibleTab, setVisibleTab] = useState(data[activeTabIndex]);

	return (
		<div className={cn(css.tabs, className)}>
			<ul className={css.tabs__list}>
				{data.map(tab => (
					<li className={css.tabs__item} key={tab.id}>
						<button
							className={cn(css.tabs__button, {
								[css.tabs__button_active]: tab.id === activeTab.id
							})}
							onClick={() => setActiveTab(tab)}
						>
							{tab.label}
						</button>
					</li>
				))}
			</ul>

			<div
				className={cn(css.tabs__content, {
					[css.tabs__content_show]: visibleTab.id === activeTab.id,
					[css.tabs__content_hide]: visibleTab.id !== activeTab.id
				})}
				onAnimationEnd={() => setVisibleTab(activeTab)}
			>
				{visibleTab.content}
			</div>
		</div>
	);
};

export default Tabs;
