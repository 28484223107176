import { createSlice } from '@reduxjs/toolkit';

const deviceInitialState = {
	width: null,
	height: null,
	lang: null
};

export const slice = createSlice({
	name: 'device',
	initialState: deviceInitialState,
	reducers: {
		setDeviceWindowProps: (state, { payload }) => {
			state.width = payload.width;
			state.height = payload.height;
		},
		setLang: (state, { payload }) => {
			state.lang = payload;
		}
	}
});

export const { setDeviceWindowProps, setLang } = slice.actions;

export const selectDeviceSize = state => state.device.width;
export const selectDeviceHeight = state => state.device.height;
export const selectLang = state => state.device.lang;

export default slice.reducer;
