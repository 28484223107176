import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

import ru from './locales/ru/translation.json';
import en from './locales/en/translation.json';
import ch from './locales/ch/translation.json';

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		// Standard language used
		fallbackLng: 'ru',
		debug: false,
		detection: {
			order: ['localStorage', 'queryString', 'cookie'],
			caches: ['localStorage', 'cookie']
		},
		backend: {
			backends: [LocalStorageBackend],
			backendOptions: [
				{
					expirationTime: 7 * 24 * 60 * 60 * 1000 // 7 days
				}
			]
		},
		resources: {
			ru: {
				translation: ru
			},
			en: {
				translation: en
			},
			ch: {
				translation: ch
			}
		},
		react: {
			useSuspense: false
		},
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
