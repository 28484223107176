import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { selectDeviceSize } from '../../reducers/deviceSlice';

import './index.scss';

const BaloonName = ({
	className,
	name,
	coords,
	zoom,
	current,
	currentBaloon
}) => {
	let result = 17;
	let scale = 1 / zoom;
	const wWidth = useSelector(selectDeviceSize);

	if (wWidth > 767) {
		if (!current) {
			if (zoom === 2) {
				scale = 1 / zoom + 0.1;
				result = 5;
			} else if (zoom >= 3) {
				scale = 1 / zoom + 0.2;
				result = 5;
			}
		} else {
			if (zoom > 1 && zoom < 2) {
				scale = 1 / zoom + 0.3;
				result = 19;
			} else if (zoom === 2) {
				scale = 1 / zoom + 0.4;
				result = 15;
			} else if (zoom >= 3) {
				scale = 1 / zoom + 0.4;
				result = 15;
			}
		}
	} else {
		if (!current) {
			if (zoom < 3) {
				scale = 1 / zoom;
			} else if (zoom === 4) {
				scale = 1 / zoom + 0.15;
			}
		} else {
			if (zoom < 2) {
				scale = 1 / zoom - 0.1;
			} else if (zoom === 2) {
				scale = 1 / zoom + 0.2;
			} else {
				scale = 1 / zoom + 0.3;
			}
		}
		result = current ? 6 : 0;
	}

	return (
		<span
			className={cn(`${className} baloon-name`, {
				'baloon-name_current': current,
				'baloon-name_visible': zoom >= 2 && currentBaloon
			})}
			style={{
				gridArea: coords,
				transform: `translate(-50%, calc(100% + ${result}px)) scale(${scale})`
			}}
		>
			{name}
		</span>
	);
};

export default BaloonName;
