import { createSlice } from '@reduxjs/toolkit';

import { loadData as loadDataApi } from '../api';

const catalogInitialState = {
	main: {},
	catalog: [],
	title: '',
	isLoading: false,
	isLoaded: false,
	error: null
};

export const slice = createSlice({
	name: 'catalog',
	initialState: catalogInitialState,
	reducers: {
		getDataStart: state => {
			state.isLoading = true;
		},
		getDataSuccess: (state, { payload }) => {
			state.title = payload.title;
			state.main = payload.main;
			state.catalog = payload.catalog;
			state.isLoading = false;
			state.isLoaded = true;
		},
		getDataFailure: (state, { payload }) => {
			state.error = payload;
			state.isLoading = false;
		}
	}
});

const { getDataStart, getDataSuccess, getDataFailure } = slice.actions;

export const loadCatalog = (path, lang) => async dispatch => {
	dispatch(getDataStart());
	try {
		const data = await loadDataApi(path, lang);
		dispatch(getDataSuccess(data));
	} catch (err) {
		dispatch(getDataFailure({ error: err.toString() }));
	}
};

export const selectCatalog = state => state.catalog;

export default slice.reducer;
