import React, { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
	useParams,
	useHistory,
	useLocation
} from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { loadStocks } from '../../reducers/stocksSlice';
import Home from '../../pages/Home';
import QrSuhar from '../../pages/QrSuhar';
import About from '../../pages/About';
import Catalog from '../../pages/Catalog';
import Leopardus from '../../pages/Leopardus';
import Stocks from '../../pages/Stocks';
import Stock from '../../pages/Stock';
import Contacts from '../../pages/Contacts';
import Map from '../../pages/Map';
import InterestingFacts from '../../pages/InterestingFacts';
import Product from '../../pages/Product';
import NotFound from '../../pages/NotFound';

import { setDeviceWindowProps } from '../../reducers/deviceSlice';
import { DOMAIN_URL } from '../../constants';

import './index.scss';

const Pages = () => {
	const { i18n } = useTranslation();
	const { lang } = useParams();
	const history = useHistory();
	const location = useLocation();
	const root = document.querySelector('.root');

	useEffect(() => {
		root.scrollTo(0, 0);
	}, [location]);

	useEffect(() => {
		if (!(lang === 'ru' || lang === 'en' || lang === 'ch')) {
			const oldLang = localStorage.getItem('i18nextLng') || 'ru';
			i18n.changeLanguage(oldLang);
			history.push(`${DOMAIN_URL}${oldLang}/not-found`);
		} else {
			i18n.changeLanguage(lang);
		}
	}, [lang, history]);

	return (
		<Switch>
			<Route exact path={`${DOMAIN_URL}:lang`} component={Home} />
			<Route exact path={`${DOMAIN_URL}:lang/qr-rusahar`} component={QrSuhar} />
			<Route exact path={`${DOMAIN_URL}:lang/about`} component={About} />
			<Route exact path={`${DOMAIN_URL}:lang/catalog`} component={Catalog} />
			<Route exact path={`${DOMAIN_URL}:lang/leopard`} component={Leopardus} />
			<Route exact path={`${DOMAIN_URL}:lang/stocks`} component={Stocks} />
			<Route exact path={`${DOMAIN_URL}:lang/stock/:id`} component={Stock} />
			<Route
				exact
				path={`${DOMAIN_URL}:lang/info`}
				component={InterestingFacts}
			/>
			<Route exact path={`${DOMAIN_URL}:lang/imap`} component={Map} />
			<Route exact path={`${DOMAIN_URL}:lang/contacts`} component={Contacts} />
			<Route
				exact
				path={`${DOMAIN_URL}:lang/product/:id`}
				component={Product}
			/>
			<Route path={`${DOMAIN_URL}:lang/not-found`} component={NotFound} />
			<Redirect from='*' to={`${DOMAIN_URL}${lang}/not-found`} />
		</Switch>
	);
};

const App = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		function updateDeviceProps() {
			const width = window.innerWidth;
			const height = window.innerHeight;

			dispatch(setDeviceWindowProps({ width, height }));

			const vh = height * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);

			const root = document.getElementById('root');
			const scrollWidth = width - root.clientWidth;
			document.documentElement.style.setProperty(
				'--scroll-width',
				`${scrollWidth}px`
			);
		}

		updateDeviceProps();
		window.addEventListener('resize', updateDeviceProps);

		return () => {
			window.removeEventListener('resize', updateDeviceProps);
		};
	}, []);

	useEffect(() => {
		dispatch(loadStocks('stoks', localStorage.getItem('i18nextLng') || 'ru'));
	}, []);

	const oldLang = localStorage.getItem('i18nextLng') || 'ru';

	return (
		<HelmetProvider>
			<Router>
				<Switch>
					<Route path={`${DOMAIN_URL}:lang`} component={Pages} />
					<Redirect from={`${DOMAIN_URL}`} to={`${DOMAIN_URL}${oldLang}`} />
				</Switch>
			</Router>
		</HelmetProvider>
	);
};

export default App;
