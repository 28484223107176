import { createSlice } from '@reduxjs/toolkit';

import { loadData as loadDataApi } from '../api';

const pinInitialState = {
	pin: {},
	isLoadingPin: false,
	isLoadedPin: false,
	errorPin: null
};

export const slice = createSlice({
	name: 'pin',
	initialState: pinInitialState,
	reducers: {
		getDataStart: state => {
			state.isLoadingPin = true;
		},
		getDataSuccess: (state, { payload }) => {
			state.pin = payload.map;
			state.isLoadingPin = false;
			state.isLoadedPin = true;
		},
		getDataFailure: (state, { payload }) => {
			state.errorPin = payload;
			state.isLoadingPin = false;
		}
	}
});

const { getDataStart, getDataSuccess, getDataFailure } = slice.actions;

export const loadPin = (path, lang) => async dispatch => {
	dispatch(getDataStart());
	try {
		const data = await loadDataApi(path, lang);
		dispatch(getDataSuccess(data));
	} catch (err) {
		dispatch(getDataFailure({ error: err.toString() }));
	}
};

export const selectPin = state => state.pin;

export default slice.reducer;
