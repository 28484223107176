import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { loadShortUrl, selectShortUrl } from '../../reducers/shortUrlSlice';
import Layout from '../../components/Layout';
import { DOMAIN_URL } from '../../constants';

import './index.scss';

const QrSuhar = () => {
	const dispatch = useDispatch();
	const { url, isLoaded } = useSelector(selectShortUrl);
	const history = useHistory();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(loadShortUrl('shorturl'));
	}, []);

	useEffect(() => {
		if (isLoaded) {
			const domain = `${window.location.protocol}//${window.location.host}`;
			if (url.indexOf(domain) !== -1) {
				const newUrl = url.replace(`${domain}/`, '');
				history.push(`${DOMAIN_URL}${newUrl}`);
			} else {
				window.location.href = url;
			}
		}
	}, [isLoaded]);

	return (
		<Layout>
			<Helmet>
				<title>{t('page_title.home')}</title>
			</Helmet>
			<section className='redirect'>
				<h4 className='redirect__title'>{t('redirect.title')}</h4>
				<p className='redirect__desc'>{t('redirect.desc')}</p>
			</section>
		</Layout>
	);
};

export default QrSuhar;
