import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swiper from 'react-id-swiper';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { selectAbout, loadAbout } from '../../reducers/aboutSlice';
import { selectDeviceSize, selectLang } from '../../reducers/deviceSlice';
import { IMG_URL, UPLOAD_IMG_URL } from '../../constants';

import 'swiper/swiper.scss';
import './index.scss';
import Layout from '../../components/Layout';

const About = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const wWidth = useSelector(selectDeviceSize);
	const ref = useRef(null);

	const lang = useSelector(selectLang);
	useEffect(() => {
		if (lang) {
			dispatch(loadAbout('about', lang));
		}
	}, [lang]);

	const about = useSelector(selectAbout);
	const {
		title,
		desc,
		quote,
		content,
		img,
		srcSet,
		percentage,
		photo,
		isLoaded
	} = about;

	const params = {
		loop: true,
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 24,
		preventClicks: false,
		preventClicksPropagation: false,
		breakpoints: {
			768: {
				slidesPerView: 2
			}
		}
	};

	const goNext = () => {
		if (ref.current !== null && ref.current.swiper !== null) {
			ref.current.swiper.slideNext();
		}
	};

	const goPrev = () => {
		if (ref.current !== null && ref.current.swiper !== null) {
			ref.current.swiper.slidePrev();
		}
	};

	return (
		<Layout>
			<Helmet>
				<title>{t('page_title.about')}</title>
			</Helmet>
			{isLoaded && (
				<>
					<section className='about'>
						<div className='container'>
							<div className='row'>
								<div className='col-12'>
									<h1
										className='about__title h2'
										dangerouslySetInnerHTML={{ __html: title }}
									></h1>
									{wWidth < 768 && (
										<p
											className='about__desc desc2'
											dangerouslySetInnerHTML={{ __html: desc }}
										></p>
									)}
									<div className='about__wrap about-wrap'>
										<div className='about-wrap__img-wrap'>
											<img
												src={`${UPLOAD_IMG_URL}${img}`}
												alt=''
												srcSet={srcSet}
											/>
										</div>
										<div className='about-wrap__content about-content'>
											{wWidth > 767 && (
												<p
													className='about-content__desc desc2'
													dangerouslySetInnerHTML={{ __html: desc }}
												></p>
											)}
											<blockquote
												className='about-content__blockquote'
												dangerouslySetInnerHTML={{ __html: quote }}
											></blockquote>
											<div
												className='about-content__b-content b-content'
												dangerouslySetInnerHTML={{ __html: content }}
											></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className='about-percentage'>
						<div className='container'>
							<div className='row'>
								<div className='col-12'>
									<ul className='about-percentage__list percentage-list'>
										{percentage.map(({ id, title, desc }) => {
											return (
												<li
													key={id}
													className='percentage-list__item percentage-item'
												>
													<p
														className='percentage-item__title'
														dangerouslySetInnerHTML={{ __html: title }}
													></p>
													<p
														className='percentage-item__desc'
														dangerouslySetInnerHTML={{ __html: desc }}
													></p>
												</li>
											);
										})}
									</ul>
								</div>
							</div>
						</div>
						{wWidth > 1439 && (
							<img
								src={`${IMG_URL}/about/bg-lg.png`}
								srcSet={`${IMG_URL}/about/bg-lg@2x.png 2x, ${IMG_URL}/about/bg-lg@3x.png 3x`}
								alt=''
								className='about-percentage__prev'
							/>
						)}
						{wWidth < 1440 && wWidth > 1023 && (
							<img
								src={`${IMG_URL}/about/bg-md.png`}
								srcSet={`${IMG_URL}/about/bg-md@2x.png 2x, ${IMG_URL}/about/bg-md@3x.png 3x`}
								alt=''
								className='about-percentage__prev'
							/>
						)}
						{wWidth < 1024 && wWidth > 767 && (
							<img
								src={`${IMG_URL}/about/bg-sm.png`}
								srcSet={`${IMG_URL}/about/bg-sm@2x.png 2x, ${IMG_URL}/about/bg-sm@3x.png 3x`}
								alt=''
								className='about-percentage__prev'
							/>
						)}
						{wWidth < 768 && (
							<img
								src={`${IMG_URL}/about/bg-xs.png`}
								srcSet={`${IMG_URL}/about/bg-xs@2x.png 2x, ${IMG_URL}/about/bg-xs@3x.png 3x`}
								alt=''
								className='about-percentage__prev'
							/>
						)}
					</section>

					<section className='photo'>
						<div className='container'>
							<div className='row'>
								<div className='col-12'>
									<h2
										className='photo__title'
										dangerouslySetInnerHTML={{ __html: photo.title }}
									></h2>
									<div className='photo__slider-wrap'>
										<Swiper ref={ref} {...params} containerClass='photo-slider'>
											{photo.gallery.map(({ id, img, srcSet, title, desc }) => {
												return (
													<figure className='photo__prev photo-prev' key={id}>
														<img
															src={`${UPLOAD_IMG_URL}${img}`}
															alt=''
															srcSet={srcSet}
															className='photo-prev__img'
														/>
														<figcaption className='photo-prev__info'>
															<p
																className='h5'
																dangerouslySetInnerHTML={{ __html: title }}
															></p>
															<p
																className='desc'
																dangerouslySetInnerHTML={{ __html: desc }}
															></p>
														</figcaption>
													</figure>
												);
											})}
										</Swiper>
										<button
											className='photo__sl-prev sl-btn sl-btn_prev'
											onClick={goPrev}
										></button>
										<button
											className='photo__sl-next sl-btn sl-btn_next'
											onClick={goNext}
										></button>
									</div>
								</div>
							</div>
						</div>
					</section>
				</>
			)}
		</Layout>
	);
};

export default About;
