import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swiper from 'react-id-swiper';
import { useTranslation } from 'react-i18next';

import CatalogSlide from '../CatalogSlide';
import { selectData, loadData } from '../../reducers/dataSlice';
import { selectLang } from '../../reducers/deviceSlice';

import 'swiper/swiper.scss';
import './index.scss';

const CatalogSect = ({ className = '' }) => {
	const dispatch = useDispatch();
	const ref = useRef(null);
	const params = {
		loop: false,
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 24,
		preventClicks: false,
		preventClicksPropagation: false,
		breakpoints: {
			768: {
				slidesPerView: 2
			},
			1024: {
				slidesPerView: 3
			}
		}
	};

	const { t } = useTranslation();

	const data = useSelector(selectData);
	const { catalogSlider, isLoaded } = data;
	const lang = useSelector(selectLang);
	useEffect(() => {
		if (lang) {
			dispatch(loadData('main', lang));
		}
	}, [lang]);

	const goNext = () => {
		if (ref.current !== null && ref.current.swiper !== null) {
			ref.current.swiper.slideNext();
		}
	};

	const goPrev = () => {
		if (ref.current !== null && ref.current.swiper !== null) {
			ref.current.swiper.slidePrev();
		}
	};

	return (
		<>
			{isLoaded && (
				<section className={`catalog-sect ${className}`}>
					<div className='container'>
						<div className='row'>
							<div className='col-12'>
								<h2 className='catalog-sect__title'>{t('titles.catalog')}</h2>
								<div className='catalog-sect__slider-wrap slider-wrap'>
									<Swiper ref={ref} {...params} containerClass='catalog-slider'>
										{catalogSlider.map(el => {
											return (
												<div className='catalog-slider__item' key={el.id}>
													<CatalogSlide {...el} />
												</div>
											);
										})}
									</Swiper>
									<button
										className='slider-wrap__sl-prev sl-btn sl-btn_prev'
										onClick={goPrev}
									></button>
									<button
										className='slider-wrap__sl-next sl-btn sl-btn_next'
										onClick={goNext}
									></button>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</>
	);
};

export default CatalogSect;
