import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { selectData, loadData } from '../../reducers/dataSlice';
import { selectDeviceSize, selectLang } from '../../reducers/deviceSlice';
import { wherebuySelector } from '../../reducers/wherebuySlice';
import { IMG_URL, UPLOAD_IMG_URL, DOMAIN_URL } from '../../constants';
import CatalogSect from '../../components/CatalogSect';
import StocksSect from '../../components/StocksSect';
import ContactsForm from '../../components/ContactsForm';
import ContactsItem from '../../components/ContactsItem';
import Wherebuy from './sections/Wherebuy';
import Layout from '../../components/Layout';

import { handleAnchor, smoothScrsoll } from '../../helpers';

import 'swiper/swiper.scss';
import './index.scss';

const Home = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const wWidth = useSelector(selectDeviceSize);
	const { data: wherebuyData } = useSelector(wherebuySelector);

	const [animate, setAnimate] = useState(false);
	const lang = useSelector(selectLang);
	const data = useSelector(selectData);

	const location = useLocation();

	const { main, award, interestingFacts, contacts, isLoaded } = data;

	useEffect(() => {
		if (lang) {
			dispatch(loadData('main', lang));
		}
	}, [lang]);

	useEffect(() => {
		if (!animate && isLoaded) {
			setTimeout(() => {
				setAnimate(!animate);
			}, 100);
		}
	}, [animate, isLoaded]);

	useEffect(() => {
		if (location.hash) {
			setTimeout(() => {
				smoothScrsoll(location.hash);
			}, 100);
		}
	}, [location, isLoaded]);

	return (
		<Layout>
			<Helmet>
				<title>{t('page_title.home')}</title>
			</Helmet>

			{isLoaded && (
				<>
					{!!main.second_title && (
						<section
							className={cn('home-top', {
								'home-top_animate': animate && !!main.second_title
							})}
						>
							<div className='container'>
								<div className='row'>
									<div className='col-12'>
										<h1
											className='home-top__title'
											dangerouslySetInnerHTML={{ __html: main.title }}
										></h1>
										<p
											className='home-top__desc'
											dangerouslySetInnerHTML={{ __html: main.desc }}
										></p>
										<div className='home-top__link-container'>
											{!!wherebuyData && (
												<a
													href='#wherebuy'
													className='home-top__link'
													onClick={handleAnchor}
												>
													{wherebuyData.title}?
												</a>
											)}
										</div>
										<div className='home-top__pack'>
											<img
												src={`${IMG_URL}/home/pack-new.png`}
												alt='Русский сахар'
											/>
											{wWidth > 1023 && (
												<img
													src={`${IMG_URL}/icons/home/sugar-border.svg`}
													alt=''
												/>
											)}
										</div>
										<div className='home-top__trophy trophy'>
											<img
												src={`${IMG_URL}/home/trophy.png`}
												alt=''
												className='trophy__img'
												srcSet={`${IMG_URL}/home/trophy@2x.png 2x, ${IMG_URL}/home/trophy@3x.png 3x`}
											/>
											{wWidth > 767 && (
												<div className='trophy__info trophy-info'>
													<p
														className='trophy-info__title'
														dangerouslySetInnerHTML={{
															__html: main.second_title
														}}
													></p>
													<p
														className='trophy-info__desc'
														dangerouslySetInnerHTML={{
															__html: main.second_desc
														}}
													></p>
												</div>
											)}
										</div>
										{wWidth > 767 && (
											<>
												<div className='home-top__cup-wrap cup-wrap'>
													<img
														src={`${IMG_URL}/home/saucer.png`}
														srcSet={`${IMG_URL}/home/saucer@2x.png 2x, ${IMG_URL}/home/saucer@3x.png 3x`}
														alt=''
														className='cup-wrap__saucer'
													/>
													<img
														src={`${IMG_URL}/home/cup.png`}
														srcSet={`${IMG_URL}/home/cup@2x.png 2x, ${IMG_URL}/home/cup@3x.png 3x`}
														alt=''
														className='cup-wrap__cup'
													/>
												</div>
												<img
													src={`${IMG_URL}/icons/home/sugar3.svg`}
													alt=''
													className='home-top__decor home-top__decor_sugar3'
												/>
												<img
													src={`${IMG_URL}/icons/home/stick-sugar.svg`}
													alt=''
													className='home-top__decor home-top__decor_stick'
												/>
												<img
													src={`${IMG_URL}/icons/home/sugar1.svg`}
													alt=''
													className='home-top__decor home-top__decor_sugar1'
												/>
												<img
													src={`${IMG_URL}/icons/home/sugar6.svg`}
													alt=''
													className='home-top__decor home-top__decor_sugar6'
												/>
											</>
										)}
										<img
											src={`${IMG_URL}/icons/home/sugar4.svg`}
											alt=''
											className='home-top__decor home-top__decor_sugar4'
										/>
										<img
											src={`${IMG_URL}/icons/home/sugar5.svg`}
											alt=''
											className='home-top__decor home-top__decor_sugar5'
										/>
										{wWidth > 767 && wWidth < 1023 && (
											<>
												<img
													src={`${IMG_URL}/icons/home/sugar7.svg`}
													alt=''
													className='home-top__decor home-top__decor_sugar7'
												/>
											</>
										)}
										<img
											src={`${IMG_URL}/icons/home/sugar8.svg`}
											alt=''
											className='home-top__decor home-top__decor_sugar8'
										/>
										{wWidth > 767 && (
											<img
												src={`${IMG_URL}/icons/home/spoon2.svg`}
												alt=''
												className='home-top__decor home-top__decor_spoon2'
											/>
										)}
										<img
											src={`${IMG_URL}/icons/home/cup.svg`}
											alt=''
											className='home-top__decor home-top__decor_cup'
										/>
									</div>
								</div>
							</div>
							{wWidth > 767 && (
								<>
									<img
										src={`${IMG_URL}/icons/home/sugar2.svg`}
										alt=''
										className='home-top__decor home-top__decor_sugar2'
									/>
									<img
										src={`${IMG_URL}/icons/home/stick-sugar2.svg`}
										alt=''
										className='home-top__decor home-top__decor_stick2'
									/>
									<img
										src={`${IMG_URL}/icons/home/spoon.svg`}
										alt=''
										className='home-top__decor home-top__decor_spoon'
									/>
									<img
										src={`${IMG_URL}/icons/home/spoon3.svg`}
										alt=''
										className='home-top__decor home-top__decor_spoon3'
									/>
								</>
							)}
						</section>
					)}

					<CatalogSect />

					<section className='award'>
						<div className='container'>
							<div className='row'>
								<div className='col-12'>
									<p
										className='award__title h1'
										dangerouslySetInnerHTML={{ __html: award.title }}
									></p>
									<div className='award__trophy award-trophy'>
										<p
											className='award-trophy__title h4'
											dangerouslySetInnerHTML={{
												__html: award.second_title
											}}
										></p>
										<p
											className='award-trophy__desc desc'
											dangerouslySetInnerHTML={{
												__html: award.second_desc
											}}
										></p>
									</div>
								</div>
							</div>
						</div>
						{wWidth > 1439 && (
							<img
								src={`${IMG_URL}/home/bg/lg.png`}
								srcSet={`${IMG_URL}/home/bg/lg@2x.png 2x, ${IMG_URL}/home/bg/lg@3x.png 3x`}
								alt=''
								className='award__bg'
							/>
						)}
						{wWidth < 1440 && wWidth > 1023 && (
							<img
								src={`${IMG_URL}/home/bg/md.png`}
								srcSet={`${IMG_URL}/home/bg/md@2x.png 2x, ${IMG_URL}/home/bg/md@3x.png 3x`}
								alt=''
								className='award__bg'
							/>
						)}
						{wWidth < 1024 && wWidth > 767 && (
							<img
								src={`${IMG_URL}/home/bg/sm.png`}
								srcSet={`${IMG_URL}/home/bg/sm@2x.png 2x, ${IMG_URL}/home/bg/sm@3x.png 3x`}
								alt=''
								className='award__bg'
							/>
						)}
						{wWidth < 768 && (
							<img
								src={`${IMG_URL}/home/bg/xs.png`}
								srcSet={`${IMG_URL}/home/bg/xs@2x.png 2x, ${IMG_URL}/home/bg/xs@3x.png 3x`}
								alt=''
								className='award__bg'
							/>
						)}
					</section>

					<section className='interesting-facts'>
						<div className='container'>
							<div className='row'>
								<div className='col-12 col-sm-6 interesting-facts__content'>
									<h2
										className='interesting-facts__title'
										dangerouslySetInnerHTML={{
											__html: interestingFacts.title
										}}
									></h2>
									<p
										className='interesting-facts__desc desc'
										dangerouslySetInnerHTML={{
											__html: interestingFacts.desc
										}}
									></p>
									<Link to={`${DOMAIN_URL}${lang}/info`} className='btn'>
										{t('read')}
									</Link>
								</div>
								<div className='col-12 col-sm-6'>
									<div className='interesting-facts__wrap-img'>
										<img
											src={`${UPLOAD_IMG_URL}${interestingFacts.img}`}
											// srcSet={interestingFacts.srcSet}
											alt=''
										/>
									</div>
								</div>
							</div>
						</div>
					</section>

					<Wherebuy id='wherebuy' />
				</>
			)}

			<StocksSect />

			{isLoaded && (
				<section className='sect-contacts'>
					<h2 className='sect-contacts__title'>{t('titles.contacts')}</h2>
					<div className='sect-contacts__wrap'>
						<ul className='sect-contacts__info contacts-info'>
							{Object.keys(contacts).map(key => {
								return <ContactsItem {...contacts[key]} type={key} key={key} />;
							})}
						</ul>
						<ContactsForm className='sect-contacts__form' />
					</div>
				</section>
			)}
		</Layout>
	);
};

export default Home;
